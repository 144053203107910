import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./MedicalCounsellingIndia/Context/Context";

// Components
import ScrollToTopButton from "./MedicalCounsellingIndia/Components/ScrollToTop";

import HeaderAlpha from "./MedicalCounsellingIndia/Components/HeaderMain";
import FooterAlpha from "./MedicalCounsellingIndia/Components/FooterMain";
import NotFoundPage from "./MedicalCounsellingIndia/Pages/404";
import HomeNewsletter from "./MedicalCounsellingIndia/Pages/Home/HomeNewsletter";
import FixedCallButton from "./MedicalCounsellingIndia/Components/AnotherButton/FixedCallButton";
import PrivacyPolicy from "./MedicalCounsellingIndia/Pages/PrivacyPolicy/PrivacyPolicy";
import FixedWhatshappButton from "./MedicalCounsellingIndia/Components/AnotherButton/FixedWhatshappButton";
import DirectAdmission from "./MedicalCounsellingIndia/Pages/DirectAdmission/DirectAdmission";
import { FixedInstagramButton } from "./MedicalCounsellingIndia/Components/AnotherButton/FixedInstagramButton";
import { FixedFacebookButton } from "./MedicalCounsellingIndia/Components/AnotherButton/FixedFacebookButton";
import { FixedLinkedInButton } from "./MedicalCounsellingIndia/Components/AnotherButton/FixedLinkedInButton";

const Header = React.lazy(() =>
  import("./MedicalCounsellingIndia/Components/HeaderMain/Header").then(
    (module) => ({
      default: module.Header,
    })
  )
);

// pages

const HomePage = lazy(() => import("./MedicalCounsellingIndia/Pages/Home"));
const AboutUs = lazy(() => import("./MedicalCounsellingIndia/Pages/AboutUs"));
const Contact = lazy(() => import("./MedicalCounsellingIndia/Pages/ContactUs"));
// const PCM = lazy(() => import("./MedicalCounsellingIndia/Pages/PCM"));
// const PCB = lazy(() => import("./MedicalCounsellingIndia/Pages/PCB"));
// const Commerce = lazy(() => import("./MedicalCounsellingIndia/Pages/Commerce"));
// const Arts = lazy(() => import("./MedicalCounsellingIndia/Pages/Arts"));
// const Diploma = lazy(() => import("./MedicalCounsellingIndia/Pages/Diploma"));

const BBA = lazy(() => import("./MedicalCounsellingIndia/Pages/Streams/BBA"));
const MBA = lazy(() => import("./MedicalCounsellingIndia/Pages/Streams/MBA"));

// const BALLB = lazy(() =>
//   import("./MedicalCounsellingIndia/Pages/Streams/BALLB")
// );
const BAMS = lazy(() => import("./MedicalCounsellingIndia/Pages/Streams/BAMS"));
const BBALLB = lazy(() =>
  import("./MedicalCounsellingIndia/Pages/Streams/BBALLB")
);
const BDS = lazy(() => import("./MedicalCounsellingIndia/Pages/Streams/BDS"));
const BHMS = lazy(() => import("./MedicalCounsellingIndia/Pages/Streams/BHMS"));
const BTech = lazy(() =>
  import("./MedicalCounsellingIndia/Pages/Streams/BTech")
);
const Diploma = lazy(() =>
  import("./MedicalCounsellingIndia/Pages/Streams/Diploma")
);
const MBBS = lazy(() => import("./MedicalCounsellingIndia/Pages/Streams/MBBS"));
const Nursing = lazy(() =>
  import("./MedicalCounsellingIndia/Pages/Streams/Nursing")
);

const Pharmacy = lazy(() =>
  import("./MedicalCounsellingIndia/Pages/Streams/Pharmacy")
);
const BioTech = lazy(() =>
  import("./MedicalCounsellingIndia/Pages/Streams/BioTech")
);
const Radiology = lazy(() =>
  import("./MedicalCounsellingIndia/Pages/Streams/Radiology")
);
const Microbiology = lazy(() =>
  import("./MedicalCounsellingIndia/Pages/Streams/Microbiology")
);
const PhysioTherapy = lazy(() =>
  import("./MedicalCounsellingIndia/Pages/Streams/PhysioTherapy")
);
const Forensic = lazy(() =>
  import("./MedicalCounsellingIndia/Pages/Streams/Forensic")
);

const MedicalCounselling = lazy(() =>
  import("./MedicalCounsellingIndia/Pages/MedicalCounselling")
);
const DyPatil = lazy(() => import("./MedicalCounsellingIndia/Pages/DyPatil"));
const Symbiosis = lazy(() =>
  import("./MedicalCounsellingIndia/Pages/Symbiosis")
);

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Theme/Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            {/* Header Start */}
            <Header topSpace={{ md: false }} type="reverse-scroll">
              <HeaderAlpha />
            </Header>

            {/* Header End */}
            {/* call button start */}
            <div className="fixed right-10 top-[50%] z-40">
              <FixedCallButton />
            </div>
            <div className="fixed left-10 top-[50%] z-40">
              <FixedWhatshappButton />
            </div>
            <div className="fixed left-10 top-[50%] z-40">
              <FixedInstagramButton />
            </div>
            <div className="fixed left-10 top-[50%] z-40">
              <FixedFacebookButton />
            </div>
            <div className="fixed left-10 top-[50%] z-40">
              <FixedLinkedInButton />
            </div>
            {/* call button end */}
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route
                    path="/"
                    element={<HomePage style={{ "--base-color": "#27ae60" }} />}
                  />
                  <Route path="about-us" element={<AboutUs />} />
                  <Route path="contact" element={<Contact />} />
                  <Route
                    path="medical-counselling"
                    element={<MedicalCounselling />}
                  />
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />

                  {/* <Route path="pcm" element={<PCM />} />
                    <Route path="pcb" element={<PCB />} />
                    <Route path="commerce" element={<Commerce />} />
                    <Route path="arts" element={<Arts />} />
                    <Route path="diploma" element={<Diploma />} /> */}
                  <Route path="bba" element={<BBA />} />
                  <Route path="mba" element={<MBA />} />
                  {/* <Route path="ba-llb" element={<BALLB />} /> */}
                  <Route path="bams" element={<BAMS />} />
                  <Route path="bba-llb" element={<BBALLB />} />
                  <Route path="bds" element={<BDS />} />
                  <Route path="bhms" element={<BHMS />} />
                  <Route path="b-tech" element={<BTech />} />
                  <Route path="diploma" element={<Diploma />} />
                  <Route path="mbbs" element={<MBBS />} />
                  <Route path="nursing" element={<Nursing />} />
                  <Route path="pharmacy" element={<Pharmacy />} />
                  <Route path="biotech" element={<BioTech />} />
                  <Route path="radiology" element={<Radiology />} />
                  <Route path="microbiology" element={<Microbiology />} />
                  <Route path="physio-therapy" element={<PhysioTherapy />} />
                  <Route path="forensic" element={<Forensic />} />

                  <Route
                    path="direct-admission/:collegeName"
                    element={<DirectAdmission />}
                  />

                  <Route
                    path="direct-admission-in-dy-patil"
                    element={<DyPatil />}
                  />
                  <Route
                    path="direct-admission-in-symbiosis"
                    element={<Symbiosis />}
                  />

                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Suspense>
            </AnimatePresence>
            {/* Footer Start */}

            <FooterAlpha />
            {/* Footer End */}

            {/* HomeNewsletter start */}
            <HomeNewsletter />
            {/* HomeNewsletter end */}
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
