import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { contactUs } from "../../Services/api";
import { ToastContainer, toast } from "react-toastify";
import { m } from "framer-motion";
import Buttons from "../../Components/Button/Buttons";
import { Col, Container } from "react-bootstrap";
import { fadeIn } from "../../Functions/GlobalAnimations";
// import MetaTags from "../../Components/MetaTags";

const ContactForm = () => {
  const [formLoading, setFormLoading] = useState(false);
  const formMethod = useForm({ mode: "all" });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethod;

  const onSubmit = (data) => {
    console.log(data);
    data["client"] = "counsellingindia.com";
    setFormLoading(true);
    contactUs(data)
      .then(() => {
        toast.success("Your message has been sent successfully!", {
          theme: "colored",
        });
        formMethod.reset();
        setTimeout(() => {
          setFormLoading(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Technical issues detected. Please try after some time.", {
          theme: "colored",
        });
        setTimeout(() => {
          setFormLoading(false);
        }, 1000);
      });
  };

  const handleKeyDown = (event) => {
    const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight"];
    const isNumberKey = !isNaN(Number(event.key)) && event.code !== "Space";

    if (!allowedKeys.includes(event.code) && !isNumberKey) {
      event.preventDefault();
    }
  };
  return (
    <>
      {/* <MetaTags
        title="Contact Us - Get in Touch for Admissions & Queries "
        description="Reach out to us for any queries regarding admissions, counselling, or services. Our team is available to assist you with all your questions and support needs. "
        keywords="Contact,contact us, admission queries, support, counselling inquiries, customer service"
      /> */}
      {/* contact form Section Start */}
      <m.section
        id="call-request"
        className="py-[130px] bg-cover bg-no-repeat overflow-hidden relative bg-center lg:py-[90px] md:py-[75px] sm:py-[50px] xs:py-0 xs:border-y"
        style={{
          backgroundImage:
            "url('/assets/img/webp/MedicalCounsellingIndia/contact/form-bg.webp')",
        }}
        {...fadeIn}
      >
        <Container className="xs:px-0">
          <m.div
            className="row justify-center"
            {...{ ...fadeIn, transition: { delay: 0.6 } }}
          >
            <Col xl={8} lg={8} md={10}>
              <div className="px-20 py-20 rounded-md shadow-[0_0_30px_rgba(0,0,0,0.08)] bg-white sm:p-20 xs:rounded-none xs:px-[3.5rem] xs:py-24">
                <span className="mb-[15px] font-medium text-center	text-[#f78c1f] text-md font-serif uppercase block sm:mb-[10px]">
                  touch with us
                </span>
                <h2 className="heading-6 w-[90%] mb-[40px] font-semibold text-center	tracking-[-1px] text-black font-serif uppercase mx-auto xs:w-full">
                  Please fill out the contact form below.
                </h2>
                <div>
                  <form className="mb-[30px]" onSubmit={handleSubmit(onSubmit)}>
                    <m.div
                      className="row justify-center"
                      {...{ ...fadeIn, transition: { delay: 0.6 } }}
                    >
                      <Col xl={6} lg={6} md={6} sm={12}>
                        <div className="mb-[33px]">
                          <input
                            type="text"
                            name="name"
                            className="rounded-[5px] relative py-[13px] px-[20px]  w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                            placeholder="Your name"
                            {...register("name", {
                              required: {
                                value: true,
                                message: "Name is required",
                              },
                            })}
                          />
                          <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px]">
                            {errors.name?.message}
                          </div>
                        </div>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12}>
                        <div className="mb-[33px]">
                          <input
                            type="text"
                            name="email"
                            className="rounded-[5px] py-[13px] px-[20px]  w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                            placeholder="Your email address"
                            {...register("email", {
                              required: {
                                value: true,
                                message: "Email is required.",
                              },
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                                message: "Enter valid email",
                              },
                            })}
                          />
                          <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px]">
                            {errors.email?.message}
                          </div>
                        </div>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12}>
                        <div className="mb-[33px]">
                          <input
                            type="number"
                            name="phone"
                            className="rounded-[5px] py-[13px] px-[20px]  w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                            placeholder="Your phone number"
                            onKeyDown={handleKeyDown}
                            {...register("phone", {
                              required: {
                                value: true,
                                message: "Phone number is required",
                              },
                              pattern: {
                                value:
                                  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
                                message: "Enter valid phone number",
                              },
                            })}
                          />
                          <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px]">
                            {errors.phone?.message}
                          </div>
                        </div>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12}>
                        <div className="mb-[33px]">
                          <input
                            type="text"
                            name="address"
                            className="rounded-[5px] py-[13px] px-[20px]  w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                            placeholder="Your address"
                            {...register("address", {
                              required: {
                                value: true,
                                message: "Address is required",
                              },
                            })}
                          />
                          <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px]">
                            {errors.address?.message}
                          </div>
                        </div>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={12}>
                        <div className="mb-[33px]">
                          <input
                            type="text"
                            name="subject"
                            className="rounded-[5px] py-[13px] px-[20px] w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                            placeholder="Your subject"
                            {...register("subject", {
                              required: {
                                value: true,
                                message: "Subject is required",
                              },
                            })}
                          />
                          <div className="absolute text-[tomato] text-[12px] translate-y-[-3px] translate-x-[10px]">
                            {errors.subject?.message}
                          </div>
                        </div>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={12}>
                        <div className="mb-[33px]">
                          <textarea
                            type="text"
                            rows={3}
                            name="message"
                            className="rounded-[5px] py-[13px] px-[20px]  w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden"
                            placeholder="Your message"
                            {...register("message", {
                              required: {
                                value: true,
                                message: "Message is required",
                              },
                            })}
                          />
                          <div className="absolute text-[tomato] text-[12px] translate-y-[-10px] translate-x-[10px]">
                            {errors.message?.message}
                          </div>
                        </div>
                      </Col>
                    </m.div>

                    <Buttons
                      type="submit"
                      className={`btn-fill text-sm leading-none font-medium tracking-[1px] !py-[17px] px-[32px] rounded-[4px] w-full uppercase mb-[5px] ${
                        formLoading ? "loading" : ""
                      }`}
                      themeColor="#f78c1f"
                      color="#fff"
                      size="lg"
                      title="Contact Us"
                    />
                  </form>
                </div>
              </div>
            </Col>
          </m.div>
        </Container>
        <ToastContainer />
      </m.section>
      {/*contact form Section End */}
    </>
  );
};

export default ContactForm;
