export const collegeData = [
  {
    course: "Direct Admission in MBBS Colleges",
    colleges: [
      {
        name: "Symbiosis Medical College for Women",
        link: "direct-admission-in-symbiosis-medical-college-for-women",
      },
      {
        name: "Dr. D. Y. Patil Medical College",
        link: "direct-admission-in-dr-d-y-patil-medical-college",
      },
      {
        name: "MGM Medical College",
        link: "direct-admission-in-mgm-medical-college",
      },
      {
        name: "Shridevi Institute of Medical Science",
        link: "direct-admission-in-shridevi-institute-of-medical-science",
      },
      {
        name: "Ananta Institute of Medical Science",
        link: "direct-admission-in-ananta-institute-of-medical-science",
      },
      {
        name: "Pacific Institute of Medical Science",
        link: "direct-admission-in-pacific-institute-of-medical-science",
      },
      {
        name: "American International Institute of Medical Science",
        link: "direct-admission-in-american-international-institute-of-medical-science",
      },
      {
        name: "Bharati Vidyapeeth Medical College",
        link: "direct-admission-in-bharati-vidyapeeth-medical-college",
      },
      {
        name: "Krishna Medical College Karad",
        link: "direct-admission-in-krishna-medical-college-karad",
      },
      {
        name: "Jawaharlal Nehru Medical College Wardha",
        link: "direct-admission-in-jawaharlal-nehru-medical-college-wardha",
      },
      {
        name: "ACS Medical College and Hospital",
        link: "direct-admission-in-acs-medical-college-and-hospital",
      },
      {
        name: "Bhaarath Medical College Chennai",
        link: "direct-admission-in-bhaarath-medical-college-chennai",
      },
      {
        name: "Sree Balaji Medical College & Hospital",
        link: "direct-admission-in-sree-balaji-medical-college-hospital",
      },
      {
        name: "Sri Ramachandra Medical College",
        link: "direct-admission-in-sri-ramachandra-medical-college",
      },
      {
        name: "Sri Lalithambigai Medical College and Hospital",
        link: "direct-admission-in-sri-lalithambigai-medical-college-and-hospital",
      },
      {
        name: "Mahatma Gandhi Medical College and Research Institute",
        link: "direct-admission-in-mahatma-gandhi-medical-college-and-research-institute",
      },
      {
        name: "Bangalore Medical College and Research Institute",
        link: "direct-admission-in-bangalore-medical-college-and-research-institute",
      },
      {
        name: "Ramaiah Medical College",
        link: "direct-admission-in-ramaiah-medical-college",
      },
      {
        name: "Dr. Bhim Rao Ambedkar Medical College",
        link: "direct-admission-in-dr-bhim-rao-ambedkar-medical-college",
      },
      {
        name: "Vydehi Medical College Bangalore",
        link: "direct-admission-in-vydehi-medical-college-bangalore",
      },
      {
        name: "NIIMS, Jaipur",
        link: "direct-admission-in-niims-jaipur",
      },
      {
        name: "Geetanjali Medical College & Hospital",
        link: "direct-admission-in-geetanjali-medical-college-hospital",
      },
      {
        name: "Kempegowda Institute of Medical Science",
        link: "direct-admission-in-kempegowda-institute-of-medical-science",
      },
    ],
  },
  // {
  //   course: "Direct Admission in MD Colleges",
  //   colleges: [
  //     {
  //       name: "Symbiosis Medical College for Women",
  //       link: "direct-admission-in-symbiosis-medical-college-for-women",
  //     },
  //     {
  //       name: "Dr. D. Y. Patil Medical College",
  //       link: "direct-admission-in-dr-d-y-patil-medical-college",
  //     },
  //     {
  //       name: "MGM Medical College",
  //       link: "direct-admission-in-mgm-medical-college",
  //     },
  //     {
  //       name: "Bharati Vidyapeeth Medical College",
  //       link: "direct-admission-in-bharati-vidyapeeth-medical-college",
  //     },
  //     {
  //       name: "Jawaharlal Nehru Medical College Wardha",
  //       link: "direct-admission-in-jawaharlal-nehru-medical-college-wardha",
  //     },
  //     {
  //       name: "Sree Balaji Medical College & Hospital",
  //       link: "direct-admission-in-sree-balaji-medical-college-hospital",
  //     },
  //     {
  //       name: "Sri Ramachandra Medical College",
  //       link: "direct-admission-in-sri-ramachandra-medical-college",
  //     },
  //     {
  //       name: "Bangalore Medical College and Research Institute",
  //       link: "direct-admission-in-bangalore-medical-college-and-research-institute",
  //     },
  //     {
  //       name: "Ramaiah Medical College",
  //       link: "direct-admission-in-ramaiah-medical-college",
  //     },
  //     {
  //       name: "Geetanjali Medical College & Hospital",
  //       link: "direct-admission-in-geetanjali-medical-college-hospital",
  //     },
  //   ],
  // },
  // {
  //   course: "Direct Admission in MS Colleges",
  //   colleges: [
  //     {
  //       name: "Symbiosis Medical College for Women",
  //       link: "direct-admission-in-symbiosis-medical-college-for-women",
  //     },
  //     {
  //       name: "Dr. D. Y. Patil Medical College",
  //       link: "direct-admission-in-dr-d-y-patil-medical-college",
  //     },
  //     {
  //       name: "MGM Medical College",
  //       link: "direct-admission-in-mgm-medical-college",
  //     },
  //     {
  //       name: "Bharati Vidyapeeth Medical College",
  //       link: "direct-admission-in-bharati-vidyapeeth-medical-college",
  //     },
  //     {
  //       name: "Jawaharlal Nehru Medical College Wardha",
  //       link: "direct-admission-in-jawaharlal-nehru-medical-college-wardha",
  //     },
  //     {
  //       name: "Sree Balaji Medical College & Hospital",
  //       link: "direct-admission-in-sree-balaji-medical-college-hospital",
  //     },
  //     {
  //       name: "Sri Ramachandra Medical College",
  //       link: "direct-admission-in-sri-ramachandra-medical-college",
  //     },
  //     {
  //       name: "Bangalore Medical College and Research Institute",
  //       link: "direct-admission-in-bangalore-medical-college-and-research-institute",
  //     },
  //     {
  //       name: "Ramaiah Medical College",
  //       link: "direct-admission-in-ramaiah-medical-college",
  //     },
  //     {
  //       name: "Geetanjali Medical College & Hospital",
  //       link: "direct-admission-in-geetanjali-medical-college-hospital",
  //     },
  //   ],
  // },
  {
    course: "Direct Admission in B.Tech Colleges",
    colleges: [
      {
        name: "Symbiosis Institute of Technology",
        link: "direct-admission-in-symbiosis-institute-of-technology",
      },
      {
        name: "Symbiosis Skills and Professional University",
        link: "direct-admission-in-symbiosis-skills-and-professional-university",
      },
      {
        name: "Bharati Vidyapeeth College of Engineering",
        link: "direct-admission-in-bharati-vidyapeeth-college-of-engineering",
      },
      {
        name: "Bharati Vidyapeeth Pune",
        link: "direct-admission-in-bharati-vidyapeeth-pune",
      },
      {
        name: "VIT Vellore",
        link: "direct-admission-in-vit-vellore",
      },
      {
        name: "Manipal University",
        link: "direct-admission-in-manipal-university",
      },
      {
        name: "MIT-Pune",
        link: "direct-admission-in-mit-pune",
      },
      {
        name: "R.V. College of Engineering",
        link: "direct-admission-in-r-v-college-of-engineering",
      },
    ],
  },
  {
    course: "Direct Admission in MBA Colleges",
    colleges: [
      {
        name: "Symbiosis International Deemed University",
        link: "direct-admission-in-symbiosis-international-deemed-university",
      },
      {
        name: "Bharati Vidyapeeth",
        link: "direct-admission-in-bharati-vidyapeeth",
      },
      {
        name: "MIT World Peace University",
        link: "direct-admission-in-mit-world-peace-university",
      },
      {
        name: "Narsee Monjee Institute of Management (NMIMS)",
        link: "direct-admission-in-narsee-monjee-institute-of-management",
      },
      {
        name: "Welingkar Institute of Management Development and Research",
        link: "direct-admission-in-welingkar-institute-of-management-development-and-research",
      },
      {
        name: "Jamnalal Bajaj Institute of Management Studies (JBIMS)",
        link: "direct-admission-in-jamnalal-bajaj-institute-of-management-studies",
      },
      {
        name: "Sydenham Institute of Management Studies, Research and Entrepreneurship Education (SIMSREE)",
        link: "direct-admission-in-sydenham-institute-of-management-studies-research-and-entrepreneurship-education",
      },
    ],
  },

  // {
  //   course: "Direct Admission in M.Tech College",
  //   colleges: [
  //     {
  //       name: "Bharati Vidyapeeth College of Engineering",
  //       link: "direct-admission-in-bharati-vidyapeeth-college-of-engineering",
  //     },
  //   ],
  // },
  // {
  //   course: "Other Institutions",
  //   colleges: [

  //   ],
  // },
  // {
  //   course: "Consultancy Services",
  //   colleges: [
  //     {
  //       name: "Education Consultant in Pune",
  //       link: "education-consultant-in-pune",
  //     },
  //     {
  //       name: "Consultant for MBBS Admission",
  //       link: "consultant-for-mbbs-admission",
  //     },
  //     {
  //       name: "Consultant for MBBS Admission in Karnataka",
  //       link: "consultant-for-mbbs-admission-in-karnataka",
  //     },
  //     {
  //       name: "Consultant for MBBS Admission in India",
  //       link: "consultant-for-mbbs-admission-in-india",
  //     },
  //     {
  //       name: "Direct Admission in Top Colleges in Pune",
  //       link: "top-colleges-in-pune",
  //     },
  //     {
  //       name: "Direct Admission in Top Colleges in Mumbai",
  //       link: "top-colleges-in-mumbai",
  //     },
  //     {
  //       name: "Direct Admission in Top Colleges in Bangalore",
  //       link: "top-colleges-in-bangalore",
  //     },
  //     {
  //       name: "Best Admission Consulting Services",
  //       link: "best-admission-consulting-services",
  //     },
  //     {
  //       name: "Consultant for MBBS Colleges in India",
  //       link: "consultant-for-mbbs-colleges-in-india",
  //     },
  //   ],
  // },
];
