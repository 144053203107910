//? Note -> College Name Should Not include "." like "Dr. D. Y. Patil Medical College"

export const DirectAdmissionContent = [
  {
    collegeName: "Symbiosis Medical College for Women",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/symbiosis/symbiosis-1.webp",
      "/assets/img/webp/MedicalCounsellingIndia/symbiosis/symbiosis-2.webp",
    ],
    collegeData: [
      "Symbiosis Medical College for Women is one of india's leading educational institutions renowned for its excellence in education and healthcare. Through its Direct Admission program, Symbiosis offers aspiring medical students a unique opportunity to become future medical leaders.",
      "At Symbiosis, the Direct Admission program is designed to identify and nurture talented individuals who are passionate about a career in medicine. We aim to admit students with exceptional academic credentials, a strong aptitude for medical Science, and a commitment to serving society through health care.",
      "As Symbiosis' dynamic curriculum integrates theoretical knowledge with hands-on clinical experience, the emphasis we place on holistic development ensures that you excel academically and develop critical thinking, communication, and empathy - skills vital to your success as a medical professional.",
    ],
    collegeAdvantage: {
      title: "Advantages Of Taking Admission in Symbiosis in 2025",
      keyPoints: [
        "Benefit from Symbiosis' prestigious reputation for academic excellence in medical education.",
        "Learn from highly qualified and experienced faculty members who are experts in their respective fields.",
        "Access modern infrastructure and cutting-edge facilities, including well-equipped laboratories and simulation centers.",
        "Experience a holistic approach to education that emphasizes not only academic proficiency but also ethical values, empathy, and social responsibility.",
        "Collaborate with students and faculty from diverse backgrounds, fostering a rich learning environment and broadening your perspectives.",
        "Gain hands-on clinical experience through rigorous practical training and exposure to real-world healthcare settings.",
        "Engage in research activities and explore your interests in various medical disciplines with ample research opportunities.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Symbiosis in 2025",
      data: [
        "Direct admission at Symbiosis involves several key steps. in addition to meeting academic requirements, applicants must meet the institution's eligibility criteria, which typically require a qualifying score on relevant entrance exams such as NEET. If interested candidates meet these criteria, they must submit their applications, along with supporting documents such as academic transcripts, standardized test scores, and other materials.",
        "After applications are submitted, the selection process begins, which may include entrance exams, interviews, and other assessments. This multidimensional approach ensures a comprehensive assessment of the candidate's suitability for the Bachelor of internal Medicine program. Symbiosis operates a merit-based admissions system to ensure that the most qualified applicants are accepted, thereby promoting a competitive but fair selection process.",
        "Throughout the admissions process, Symbiosis prioritizes providing comprehensive advice and guidance to assist prospective students. Qualified advisors are available to answer any questions, offer assistance, and provide valuable information about the program. This personalized support system is designed to facilitate a smooth and transparent admissions process, ensuring students feel supported and informed every step of the way.",
      ],
    },
    whyChoose: {
      title: "Why Choose Symbiosis Admission in 2025",
      data: [
        "Choosing Symbiosis to enroll in an internal medicine degree program offers many advantages. With a commitment to academic excellence, you'll receive a rigorous curriculum taught by experienced faculty who prioritize a well-rounded education. Using state-of-the-art facilities ensures you have access to hands-on training and research opportunities, preparing you to face real-world challenges. Symbiosis' global recognition gives you a solid foundation for your career, while its supportive community encourages collaboration and communication. The Symbiosis program is accredited by regulatory bodies such as the Medical Council of india, ensuring high-quality education and a transformative journey to become a competent and compassionate healthcare professional.",
      ],
    },
    eligibility: {
      title: "Eligibility Criteria Admission in Symbiosis in 2025",
      keyPoints: [
        "Completion of 10+2 or equivalent examination from a recognized board or university. A student must score at least 538 marks in NEET entrance exam.",
        "Attainment of a minimum aggregate score in the qualifying examination, as specified by Symbiosis.",
        "Study of Physics, Chemistry, Biology, and English as compulsory subjects in the qualifying examination.",
        "Participation in the National Eligibility cum Entrance Test (NEET) and achievement of a qualifying score, by Medical Council of india (MCI) and University Grants Commission (UGC) regulations.",
        "The minimum age requirement of 17 years for admission to the program at Symbiosis, with a possible upper age limit determined by the institution or regulatory authorities.",
        "Fulfillment of any additional criteria stipulated by Symbiosis, such as submission of relevant documents, medical fitness certificates, and compliance with the institution's admission policies.",
      ],
    },
    requireDocuments: {
      title: "Required Documents Admission in Symbiosis in 2025",
      keyPoints: [
        "Copies of mark sheets or transcripts from 10th and 12th grade or equivalent examinations, demonstrating academic performance.",
        "Certificate of passing the 10+2 or equivalent examination issued by the respective board or university.",
        "Valid scorecard of the National Eligibility cum Entrance Test (NEET), demonstrating eligibility for admission to medical programs.",
        "Transfer certificate from the previously attended educational institution, certifying the student's academic history and conduct.",
        "Migration certificate (if applicable), indicating the student's transition from one educational board or university to another.",
        "Valid identification proof, such as Aadhar card, passport, or driver's license, for verification purposes.",
        "Recent passport-sized photographs of the applicant for admission and identification purposes.",
        "Category certificate (e.g., SC/ST/OBC/EWS) issued by the competent authority, for candidates seeking admission under reserved categories.",
        "income certificate issued by the competent authority for candidates applying for fee concessions or scholarships based on financial need.",
      ],
    },
  },
  {
    collegeName: "Dr D Y Patil Medical College",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/dy-patil/dy-patil-2.webp",
      "/assets/img/webp/MedicalCounsellingIndia/dy-patil/dy-patil-1.webp",
    ],
    collegeData: [
      "Aspirant doctors get an opportunity to pursue their passion and excel in their chosen field as per DY Patel Medical College Management Quota. Don't let a low score hinder your aspirations. instead, grab this golden opportunity to get into one of the leading medical institutions in Maharashtra.",
      "Established in 1996, D Patel Medical College is known for its excellence in medical education. The university is accredited by NAAC and ISO and maintains high standards of academic quality and infrastructure. in addition, it is considered a university under the UGC Act, 1956, enhancing its position as a prestigious institution in the field of medicine.",
      "Don't let the fear of limited opportunities hold you back from your ambitions. With Dy Patil Medical College Management Quota, you can open the door to your future in medicine. Contact us now to get a direct entry and take the first step towards realizing your aspirations.",
    ],
    courseAvailable: {
      title: "Courses Available At Dy Patil Medical College in 2025",
      courseName: [
        "Orthopedics",
        "General Surgery",
        "Ophthalmology",
        "ENT (Ear, Nose, Throat)",
        "Gynecology",
        "Pediatrics",
        "Radiodiagnosis",
        "Pathology",
      ],
    },
    rewardsRecognition: {
      title: "Rewards and Recognition in 2025",
      data: [
        "University has been awarded A++ status by National Assessment and Accreditation Council (NAAC). (3.64 on a 4 point scale).",
        "The National institutional Ranking Framework (NIRF) has ranked Dr. D. Y. Patil Medical College, Hospital and Research Centre, Pune at 15th position all over india and the 1st position (only the Medical College) in Maharashtra by MHRD in 2024.",
        "All the four hospitals of Dr. D. Y. Patil Vidyapeeth (Medical, Dental, Ayurveda, and Homoeopathy) have been accredited by the National Accreditation Board for Hospitals & Healthcare Providers (NABH).",
        "Dr. D. Y. Patil Medical College, Hospital and Research Centre, Pune was conferred a National Award in Green Hospital Category by the Association of HealthCare Providers, New Delhi in February 2020.",
        "Dr. D. Y. Patil Medical College, Hospital and Research Centre, Pune has won a coveted position in the Guinness Book of World Records by screening the most number of individuals in one hour for hypertension on the eve of World Heart Day 2018.",
      ],
    },
    eligibility: {
      title: "Eligibility For Medical College Management Quota/NRI in 2025",
      keyPoints: [
        "Applicants must have completed their 10+2 or equivalent examination with Physics, Chemistry, Biology/Biotechnology, and English as core subjects.",
        "Candidates must have secured a minimum aggregate score in the qualifying examination, usually around 50% to 60% depending on the college’s policies.",
        "While some colleges may admit students directly based on their qualifying exam scores, others may require candidates to have appeared and performed well in national or state-level medical entrance exams such as NEET (National Eligibility cum Entrance Test) or AIIMS (All india institute of Medical Science) entrance exam.",
        "Management quota admissions typically involve a certain percentage of seats reserved by the college management for students who meet additional criteria, such as merit, special achievements, or payment of higher fees. These seats are filled at the discretion of the college management.",
        "interested candidates must apply directly to the college for the management fee. They may be asked to submit relevant documents, including transcripts, proof of identity, and other documents specified by the university.",
        "Student's birth certificate, high school diploma, basic certificate from a recognized university.",
        "DY Patil Medical College collaborates with international organizations like World Health Organization, Harvard University and Johns Hopkins University.",
      ],
    },
    requireDocuments: {
      title: "Documentation For Admission Through Management Quota/NRI in 2025",
      keyPoints: [
        "10+2 Marks Card: Original and photocopies of marksheets from your 10+2 or equivalent examination.",
        "NEET UG/PG/SS Scorecard: Original and photocopies of your NEET (UG/PG/SS) scorecard.",
        "Transfer Certificate: Transfer certificate from the school/college last attended.",
        "Medical Certificate: Certificate verifying your medical fitness from a registered medical practitioner.",
        "Demand Draft (DD) of the Annual Fee: A DD of the annual fee amount as per the college's requirement.",
        "Passport-size Photographs: Recent passport-sized photographs for identification purposes.",
        "Adhar Card Copy: Copy of your Aadhar Card for identification and address proof.",
      ],
    },
  },
  {
    collegeName: "Mgm Medical College",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/mgm-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/mgm-2.jpg",
    ],
    collegeData: [
      "Mgm Medical College is one of the prominent institutions offering top-tier medical education. Located in a well-connected area, the college provides aspiring medical professionals with comprehensive learning opportunities, including theoretical and clinical training, research, and more.",
      "The Direct Admission program at Mgm Medical College is designed to admit students with a high potential for success in medicine. This program combines academic excellence with real-world medical training, ensuring students graduate as skilled healthcare professionals.",
      "With a focus on holistic development, the curriculum emphasizes both academic knowledge and practical experience in the healthcare field. Mgm Medical College prepares students to tackle the complex challenges of modern medicine with expertise and compassion.",
    ],
    collegeAdvantage: {
      title: "Advantages Of Taking Admission in Mgm Medical College in 2025",
      keyPoints: [
        "Benefit from Mgm Medical College's reputation for academic excellence in medical education.",
        "Learn from highly qualified and experienced faculty members who are experts in their fields.",
        "Access state-of-the-art infrastructure and modern facilities, including well-equipped laboratories and simulation centers.",
        "Experience a holistic approach to education that emphasizes not only academic proficiency but also ethical values, empathy, and social responsibility.",
        "Collaborate with a diverse community of students and faculty, enriching your educational experience.",
        "Gain hands-on clinical experience in renowned hospitals, ensuring practical exposure to real-world medical situations.",
        "Engage in research opportunities across various medical disciplines, contributing to advancements in healthcare.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Mgm Medical College in 2025",
      data: [
        "The admission process for Mgm Medical College in 2025 involves meeting academic requirements and eligibility criteria. Applicants must have a valid score in the NEET exam and submit their applications along with necessary documents, including academic transcripts and NEET scorecards.",
        "After the submission of applications, candidates are selected based on merit. The selection process may include interviews or further assessments to ensure that the candidate is suited for the rigorous demands of the program.",
        "The college offers a transparent and supportive admission process, with qualified advisors available to guide candidates throughout. The aim is to provide clear communication and support to ensure that all candidates are well-informed about the process.",
      ],
    },
    whyChoose: {
      title: "Why Choose Mgm Medical College Admission in 2025",
      data: [
        "Mgm Medical College offers a high standard of medical education, preparing students for a successful career in healthcare.",
        "The college boasts cutting-edge facilities and a rich curriculum that prepares students for both academic and clinical success.",
        "Mgm Medical College provides ample research opportunities and ensures hands-on experience through affiliated hospitals.",
        "The program is accredited by the relevant medical councils, including the Medical Council of india, ensuring that the education meets the highest standards.",
        "Students also benefit from a diverse, collaborative environment, which enhances personal and professional growth.",
      ],
    },
    eligibility: {
      title: "Eligibility Criteria Admission in Mgm Medical College in 2025",
      keyPoints: [
        "Completion of 10+2 or equivalent examination with a minimum of 50% marks in Physics, Chemistry, Biology, and English.",
        "A valid score in the NEET UG exam is mandatory for all candidates seeking admission to the program.",
        "Candidates must meet the age requirement of 17 years by the specified date of admission, with any upper age limits based on regulatory guidelines.",
        "Candidates must also submit relevant documents such as academic certificates, NEET scorecards, and medical fitness certificates.",
      ],
    },
    requireDocuments: {
      title: "Required Documents Admission in Mgm Medical College in 2025",
      keyPoints: [
        "Mark sheets or transcripts of 10th and 12th grade or equivalent examinations.",
        "Certificate of passing 10+2 or equivalent from the respective board or university.",
        "Valid NEET UG scorecard to prove eligibility for admission.",
        "Transfer certificate from the previously attended institution.",
        "Migration certificate (if applicable), for students transferring from another board or university.",
        "Valid ID proof (Aadhar card, passport, etc.) for verification.",
        "Recent passport-sized photographs for administrative purposes.",
        "Category certificate (if applicable), for candidates seeking admission under reserved categories.",
        "income certificate for students applying for financial assistance or fee concessions.",
      ],
    },
  },
  {
    collegeName: "Shridevi Institute Of Medical Science",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/shridevi-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/shridevi-2.jpg",
    ],
    collegeData: [
      "Shridevi Institute of Medical Sciences and Research Hospital, located in Tumkur, is a leading institution that provides high-quality medical education and healthcare services. It continues to uphold its reputation as one of the leading medical colleges in the region, offering high-quality medical education and clinical training. The college offers a dynamic program designed to produce capable and compassionate doctors.",
      "Based in Tumkur, Karnataka, Shridevi Institute offers full-time degree courses in Medicine and Health Sciences. The institute boasts a highly experienced faculty and state-of-the-art campus facilities that support the academic and professional growth of students.",
      "The Admission process in 2025 is designed to select students based on merit, ensuring a competitive entry. The program blends academic learning with practical clinical exposure, ensuring graduates are equipped to meet the challenges of modern medicine.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Shridevi institute for Medical Science in 2025",
      keyPoints: [
        "Benefit from the college's strong reputation for excellence in medical education and clinical training.",
        "Learn from a team of experienced faculty members, many of whom are leaders in their medical fields.",
        "Access world-class infrastructure, including well-equipped laboratories, libraries, and advanced medical simulation centers.",
        "The program emphasizes not only academic achievement but also empathy, ethics, and social responsibility, making you a well-rounded medical professional.",
        "Collaborate with students from diverse backgrounds, enriching your learning experience and broadening your perspectives.",
        "Gain clinical experience through attachments to affiliated hospitals, providing real-world medical practice.",
        "Engage in research projects and contribute to medical advancements with numerous research opportunities.",
      ],
    },
    admissionProcess: {
      title:
        "Admission Process in Shridevi institute for Medical Science in 2025",
      data: [
        "The admission process for the program at Shridevi institute for Medical Science in 2025 requires students to meet academic and NEET eligibility criteria.",
        "After submission, the college shortlists candidates based on their merit and NEET scores. The selection process may also include interviews or counseling sessions for final admission.",
        "Qualified counselors are available to guide students through the entire admission process, ensuring transparency and clarity throughout.",
      ],
    },
    whyChoose: {
      title:
        "Why Choose Shridevi institute for Medical Science Admission in 2025",
      data: [
        "Shridevi institute offers a robust program that integrates rigorous academic training with hands-on clinical exposure.",
        "The college is well-equipped with the latest medical technologies and research opportunities, preparing students for diverse healthcare challenges.",
        "Accredited by the Medical Council of india (MCI), Shridevi Medical College ensures that students receive a globally recognized education.",
        "The institution offers a nurturing and inclusive environment, fostering both academic and personal growth in its students.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Shridevi institute for Medical Science in 2025",
      keyPoints: [
        "Completion of 10+2 or equivalent examination with a minimum of 50% in Physics, Chemistry, Biology, and English.",
        "A valid NEET UG score is mandatory for all applicants.",
        "Candidates must be at least 17 years of age as of the specified date of admission, with any applicable upper age limits as per regulatory guidelines.",
        "Candidates should provide necessary documents, including NEET scorecard, academic certificates, and medical fitness certificates.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents Admission in Shridevi institute for Medical Science in 2025",
      keyPoints: [
        "Mark sheets of 10th and 12th grade or equivalent.",
        "Certificate of passing 10+2 or equivalent.",
        "Valid NEET UG scorecard.",
        "Transfer certificate from previous educational institution.",
        "Migration certificate (if applicable).",
        "Proof of identity (Aadhar card, passport, etc.).",
        "Recent passport-sized photographs for administrative purposes.",
        "Category certificate (if applicable) for reserved category candidates.",
        "income certificate for candidates applying for fee concessions or scholarships.",
      ],
    },
  },
  {
    collegeName: "Ananta Institute Of Medical Science",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/ananta-1.webp",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/ananta-2.jpg",
    ],
    collegeData: [
      "Ananta Institute of Medical Sciences & Research Centre, the first medical college in Rajsamand district of Rajasthan, was established with the mission to provide exceptional medical education. The institute is set in the serene surroundings of the Aravalis, located just 20 km from Udaipur on the Udaipur-Nathdwara road, National Highway-8.",
      "Affiliated with the Rajasthan University of Health Sciences, the college offers 150 MBBS seats annually. The institute boasts impressive infrastructure, a supportive environment, and a faculty of highly experienced professionals from renowned institutions across India.",
      "The institute provides Full-Time UG and PG courses in various medical fields. The faculty members are experts in their respective specialties, including Anesthesiology, Community Medicine, Dermatology, General Medicine, Pediatrics, Psychiatry, and more. The well-equipped campus is designed to foster the academic growth of students.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Ananta institute of Medical Science in 2025",
      keyPoints: [
        "The institute is the pioneering medical college in Rajsamand district, dedicated to offering high-quality medical education.",
        "Situated in a serene and green environment in the Aravalis, the campus provides an ideal setting for academic pursuits.",
        "Affiliated with Rajasthan University of Health Sciences, ensuring a recognized and accredited curriculum.",
        "The college admits 150 MBBS students annually, offering substantial opportunities for aspiring doctors.",
        "Equipped with modern infrastructure and facilities that support both academic and extracurricular activities.",
        "Faculty members are experienced and come from top institutions, bringing expertise across a wide range of medical fields.",
        "Students gain in-depth knowledge across multiple specialties such as Anesthesiology, Pediatrics, Radiology, Psychiatry, and others.",
        "The campus is well-equipped with facilities that enrich the overall student learning and development experience.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Ananta institute of Medical Science in 2025",
      data: [
        "The admission process for the program at Ananta institute of Medical Science in 2025 is based on the NEET UG score. Candidates must meet the eligibility criteria and secure a qualifying score in the NEET exam.",
        "Shortlisted candidates may be called for a counseling session or interview to assess their suitability for the program.",
        "The final selection of candidates is based on their NEET score, academic performance, and performance in the counseling/interview process.",
      ],
    },
    whyChoose: {
      title: "Why Choose Ananta institute of Medical Science Admission in 2025",
      data: [
        "Ananta institute offers a comprehensive program with a strong emphasis on both theoretical knowledge and practical skills.",
        "The college provides a supportive and stimulating learning environment, fostering academic excellence and personal growth.",
        "Experienced faculty members guide students through the curriculum, providing mentorship and guidance.",
        "The institute boasts state-of-the-art facilities, including well-equipped laboratories, libraries, and a modern hospital.",
        "Ananta institute encourages students to participate in research activities and contribute to medical advancements.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Ananta institute of Medical Science in 2025",
      keyPoints: [
        "Completion of 10+2 or equivalent examination with Physics, Chemistry, Biology, and English as core subjects.",
        "A valid NEET UG score is mandatory for all applicants.",
        "Candidates must meet the minimum age requirement as specified by the Medical Council of india.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents Admission in Ananta institute of Medical Science in 2025",
      keyPoints: [
        "10th and 12th standard mark sheets and passing certificates",
        "NEET UG scorecard",
        "Transfer certificate from the previous institution",
        "Migration certificate (if applicable)",
        "Identity proof (Aadhaar card, passport, etc.)",
        "Recent passport-sized photographs",
        "Category certificate (if applicable)",
        "income certificate (if applicable)",
      ],
    },
  },
  {
    collegeName: "Pacific Institute Of Medical Science",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/pacific-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/pacific-2.jpg",
    ],
    collegeData: [
      "Pacific Institute of Medical Sciences (PIMS) is dedicated to achieving excellence in healthcare and medical education. Our aim is to deliver the best medical treatment, training, and patient care in the country, under the guidance of highly skilled and dedicated professionals and specialists.",
      "The institute brings together a team of doctors, nurses, and healthcare professionals to provide top-notch medical services across various specialties. We prioritize creating a comforting and welcoming environment for our patients and their families, understanding that hospital visits can be stressful. From routine check-ups to more serious conditions, our compassionate staff ensures a seamless and supportive healthcare experience.",
      "Located in Udaipur, Rajasthan, PIMS offers popular courses like MBBS, MD, and MS, primarily in Medicine & Health Sciences. The institute provides both undergraduate and postgraduate programs, supported by modern infrastructure and excellent facilities to enhance the learning experience of students.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Pacific institute of Medical Science in 2025",
      keyPoints: [
        "Benefit from modern infrastructure and cutting-edge medical facilities.",
        "Learn from experienced and renowned faculty members.",
        "Gain hands-on clinical experience in affiliated hospitals.",
        "Engage in research activities that contribute to the advancement of medical science.",
        "Benefit from a well-rounded education that focuses on both academic learning and practical exposure.",
      ],
    },
    admissionProcess: {
      title:
        "Admission Process in Pacific institute of Medical Science in 2025",
      data: [
        "Candidates must meet academic qualifications and NEET score criteria to apply for the program.",
        "The selection process involves a merit-based shortlisting of candidates followed by counseling sessions.",
        "Qualified advisors will guide candidates throughout the admission process, ensuring clarity and transparency.",
      ],
    },
    whyChoose: {
      title:
        "Why Choose Pacific institute of Medical Science Admission in 2025",
      data: [
        "An institution offering a diverse range of medical, paramedical, and general education all under one roof.",
        "Built on a foundation of excellence, the institution aims to enhance healthcare for all.",
        "Affiliated with an 800-bed multi-specialty hospital equipped with state-of-the-art amenities, providing practical exposure to students.",
        "Classrooms and lecture halls are designed with acoustic precision, fully ventilated, and equipped with modern audio-visual tools.",
        "Commitment to delivering high-quality teaching and training.",
        "Faculty members are highly regarded and experts in their fields.",
        "Well-equipped modern laboratories for hands-on learning.",
        "Digital library to provide access to a vast range of resources.",
        "Auditorium with acoustic treatment and a seating capacity of 200, featuring a modern audio-visual setup.",
        "On-campus residential facilities available for both staff and students.",
        "Government-approved scholarship opportunities available for eligible students.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Pacific institute of Medical Science in 2025",
      keyPoints: [
        "Completion of 10+2 with a minimum of 50% marks in Physics, Chemistry, Biology, and English.",
        "Valid NEET UG score is mandatory for all applicants.",
        "Candidates must be at least 17 years old by the date of admission.",
        "Candidates must submit necessary documents like NEET scorecards and academic certificates.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents Admission in Pacific institute of Medical Science in 2025",
      keyPoints: [
        "Mark sheets of 10th and 12th grade or equivalent.",
        "NEET UG scorecard.",
        "Transfer certificate and migration certificate (if applicable).",
        "Proof of identity and recent passport-sized photographs.",
        "Category certificate (if applicable).",
        "income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "American International Institute Of Medical Science",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/american-international-institute-1.webp",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/american-international-institute-2.webp",
    ],
    collegeData: [
      "American International Institute of Medical Sciences, located in Udaipur, Rajasthan, is dedicated to shaping future leaders in healthcare. The institute's mission is to not only produce skilled doctors but to nurture successful leaders with a lifelong passion for knowledge and service. In addition to providing top-quality education, the institution focuses on the academic, professional, and social growth of its students, preparing them to excel as clinicians, educators, and entrepreneurs.",
      "The institute offers a variety of undergraduate and postgraduate programs in Medicine & Health Sciences, all delivered in Full Time mode. The faculty is highly experienced, bringing years of expertise to the classroom. The courses offered at the institute are approved by the National Medical Commission (NMC).",
      "With a capacity of 189 seats, the American International Institute of Medical Sciences provides modern facilities and a well-structured infrastructure to support its students’ learning experience. The institute is committed to equipping students with the necessary skills to thrive in the medical field.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in American international institute of Medical Science in 2025",
      keyPoints: [
        "Focus on producing not only skilled doctors but successful leaders in healthcare.",
        "Emphasis on the academic, professional, and social growth of students.",
        "Global perspective in training, preparing students to be leaders in healthcare on an international scale.",
        "Opportunity for students to develop skills as medical teachers, clinicians, and entrepreneurs.",
        "Courses approved by the National Medical Commission (NMC).",
        "Experienced faculty members with years of expertise in the field.",
        "Offers a range of undergraduate and postgraduate programs in Medicine & Health Sciences.",
        "Modern infrastructure with well-built facilities to support student learning.",
        "A total of 189 seats available for students.",
      ],
    },
    admissionProcess: {
      title:
        "Admission Process in American international institute of Medical Science in 2025",
      data: [
        "Candidates need to meet the NEET UG score requirement and submit relevant documents.",
        "Selection will be based on merit and further interviews or counseling may follow.",
        "Advisors will assist students throughout the entire admission process for clarity and support.",
      ],
    },
    whyChoose: {
      title:
        "Why Choose American international institute of Medical Science Admission in 2025",
      data: [
        "international exposure and a curriculum aligned with global medical standards.",
        "World-class infrastructure and faculty.",
        "Opportunity to work with leading medical professionals from around the world.",
        "Accreditation ensuring a globally recognized degree.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in American international institute of Medical Science in 2025",
      keyPoints: [
        "10+2 or equivalent examination with 50% marks in Physics, Chemistry, Biology, and English.",
        "A valid NEET UG score is mandatory.",
        "Minimum age of 17 years as per admission date requirements.",
        "Submission of NEET scorecard, academic records, and ID proofs.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents Admission in American international institute of Medical Science in 2025",
      keyPoints: [
        "Mark sheets of 10th and 12th grade or equivalent.",
        "NEET UG scorecard.",
        "Proof of identity and photographs.",
        "Transfer certificate and migration certificate (if applicable).",
        "Category and income certificates (if applicable).",
      ],
    },
  },
  {
    collegeName: "Bharati Vidyapeeth Medical College",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/bharati-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/bharati-2.webp",
    ],
    collegeData: [
      "Bharati Vidyapeeth Deemed University Established in 1989, in Pune is a renowned institution known for its academic excellence and state-of-the-art infrastructure. With a vision focused on social transformation through education, the university is dedicated to producing competent, compassionate, and confident professionals across various fields, including medicine, engineering, and management. The institution offers a dynamic learning environment that emphasizes research, innovation, and competency-based education.",
      "Accredited with NAAC A++ and recognized by prestigious bodies like the Medical Council of India (MCI) and the World Health Organization (WHO), Bharati Vidyapeeth is highly regarded for its medical programs, including 150 MBBS seats at both its Pune and Sangli campuses. The university offers direct admission opportunities through the management and NRI quotas, making it an ideal choice for aspiring medical students.",
      "The university's associated teaching hospital provides invaluable hands-on learning opportunities for students, where they can gain practical experience under the guidance of skilled faculty. Bharati Vidyapeeth attracts students from diverse backgrounds and cultures, fostering a vibrant academic community that promotes cross-cultural understanding and collaboration. The institution’s commitment to research and innovation encourages students to push the boundaries of medical science and contribute to the advancement of healthcare.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Bharati Vidyapeeth Medical College in 2025",
      keyPoints: [
        "Accredited by NAAC, MCI, and WHO.",
        "State-of-the-art facilities for enhanced learning.",
        "Experienced faculty providing quality education.",
        "Focus on research, critical thinking, and innovation.",
        "Hands-on clinical exposure in teaching hospitals.",
        "Internationally recognized MBBS degree.",
        "Exposure to various medical specialties.",
        "Emphasis on ethical values and communication skills.",
        "Wide career opportunities post-graduation.",
        "Strong alumni network for guidance and support.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Bharati Vidyapeeth Medical College in 2025",
      data: [
        "Candidates need to appear for the BVIMR (Bharati Vidyapeeth Institute of Management and Research) entrance exam.",
        "Shortlisted candidates will be invited for Group Discussion and Personal Interview rounds.",
        "Candidates must submit required documents like exam scorecard, academic certificates, and identity proof.",
        "The candidate will be provisionally admitted to the program upon payment of the necessary yearly tuition fees.",
      ],
    },
    whyChoose: {
      title: "Why Choose Bharati Vidyapeeth Medical College Admission in 2025",
      data: [
        "Reputation for academic excellence and comprehensive clinical training.",
        "innovative teaching methods and faculty development programs.",
        "Wide-ranging research opportunities and student support services.",
        "Accredited by the Medical Council of india (MCI), ensuring high-quality education.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Bharati Vidyapeeth Medical College in 2025",
      keyPoints: [
        "Minimum of 50% in 10+2 in Physics, Chemistry, Biology, and English.",
        "Valid NEET UG Score.",
        "Candidates must be at least 17 years old by the time of admission.",
        "Submission of relevant academic documents and NEET scorecards.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents Admission in Bharati Vidyapeeth Medical College in 2025",
      keyPoints: [
        "Mark sheets of 10th and 12th grade or equivalent.",
        "NEET UG scorecard.",
        "Proof of identity and photographs.",
        "Transfer certificate and migration certificate (if applicable).",
        "Category and income certificates (if applicable).",
      ],
    },
  },
  {
    collegeName: "Krishna Medical College Karad",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/krishna-college-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/krishna-college-2.png",
    ],
    collegeData: [
      "Krishna Institute of Medical Sciences (KIMS) Karad, established in 1982, is a deemed-to-be-university located in Karad, Maharashtra. Recognized by the UGC, MCI, NABL, NABH, and accredited with an 'A' grade by NAAC, the institution offers a wide range of medical, dental, physiotherapy, nursing, and biotechnology courses. The university provides undergraduate, postgraduate, doctoral, and PG diploma programs, along with super speciality courses in Neurosurgery and Plastic Surgery. KIMS also offers post-doctoral fellowships in 11 different medical fields.",
      "The KIMS campus spans 57 acres amidst the picturesque landscape of Western Maharashtra. The campus is eco-friendly and well-connected by rail, road, and air. The college boasts world-class facilities, including a 1125-bed multi-specialty teaching hospital recognized by NABH, accredited diagnostic laboratories, and a 24/7 library with both on-campus and off-campus access to e-resources. KIMS provides excellent infrastructure, including spacious classrooms, a Lead Referral Laboratory, and a Molecular & Human Genetics Lab.",
      "KIMS Karad attracts students from across India and internationally. The medical college is recognized by the Medical Council of India, Malaysia, and listed in the WHO's World Directory of Medical Schools. The university has received several accolades, including being ranked 5th among the cleanest higher educational institutions in India. The hospital is well-equipped for critical care, surgeries, dialysis, and more, offering various specialized units and state-of-the-art facilities for student training. The institution also actively participates in national healthcare and outreach programs.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Krishna Medical College Karad in 2025",
      keyPoints: [
        "Wide range of courses offered at UG, PG, doctoral, and PG diploma levels in Medical, Dental, Physiotherapy, Nursing, and Biotechnology fields.",
        "Recognized by UGC, MCI, NABL, NABH, and accredited with an 'A' grade by NAAC, ensuring quality education and healthcare standards.",
        "State-of-the-art infrastructure, including a 1125-bed multi-specialty teaching hospital, diagnostic laboratories, and a Molecular & Human Genetics Lab.",
        "Well-equipped, spacious classrooms and facilities, including a Lead Referral Laboratory (first of its kind in Maharashtra) and 24/7 library with e-resources.",
        "Accredited by NABH, ensuring top-notch healthcare and patient care in the teaching hospital, which is recognized for its critical care and surgical facilities.",
        "National and international recognition, with international students from countries like the USA, UK, Sri Lanka, Canada, and more, enhancing the global exposure of students.",
        "Ranked among the cleanest higher educational institutions, demonstrating commitment to maintaining a healthy and conducive learning environment.",
        "Strong focus on practical exposure and research, with post-doctoral fellowships, super specialty programs, and active participation in national healthcare initiatives.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Krishna Medical College Karad in 2025",
      data: [
        "Candidates must apply online with NEET UG scores and other required documents.",
        "Selection is based on merit, and shortlisted candidates will be invited for counseling and interviews.",
        "The college provides assistance throughout the entire admission process.",
      ],
    },
    whyChoose: {
      title: "Why Choose Krishna Medical College Karad Admission in 2025",
      data: [
        "Reputation for excellence in medical education and clinical training.",
        "Modern medical infrastructure and hospital affiliations.",
        "Supportive environment with ample growth opportunities for students.",
        "Accreditation from relevant medical councils.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Krishna Medical College Karad in 2025",
      keyPoints: [
        "Completion of 10+2 or equivalent with 50% marks in Physics, Chemistry, Biology, and English.",
        "A valid NEET UG score is required.",
        "Candidates must be 17 years old by the date of admission.",
        "Submission of relevant documents like academic certificates and NEET scorecards.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents Admission in Krishna Medical College Karad in 2025",
      keyPoints: [
        "Mark sheets of 10th and 12th grade or equivalent.",
        "NEET UG scorecard.",
        "Proof of identity and photographs.",
        "Transfer certificate and migration certificate (if applicable).",
        "Category and income certificates (if applicable).",
      ],
    },
  },
  {
    collegeName: "Jawaharlal Nehru Medical College Wardha",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/Jawahar-college-1.png",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/Jawahar-college-2.jpg",
    ],
    collegeData: [
      "Jawaharlal Nehru Medical College (JNMC), affiliated with DMIHER Deemed to be University, stands as one of the leading MBBS colleges in Wardha, dedicated to shaping skilled and compassionate healthcare professionals. The college offers a comprehensive educational experience, combining rigorous academics with state-of-the-art infrastructure in a nurturing environment that encourages innovation and lifelong learning.",
      "With over 30 years of excellence in medical education, JNMC is renowned for its commitment to providing a complete learning experience. The college offers undergraduate MBBS courses, postgraduate M.D./M.S. programs, and super-specialization in fields like Cardiac Anaesthesia and Neuro-Surgery. The programs are recognized by the Medical Council of India, ensuring top-quality education and training.",
      "JNMC's campus spans over 125 acres and is equipped with advanced facilities such as E-classrooms, a Virtual Learning Centre, and a Clinical Skill Lab. Students benefit from hands-on learning at the Acharya Vinobha Bhave Rural Hospital, a large teaching hospital in central India. The college also emphasizes extracurricular activities and provides student support services to foster academic, personal, and professional growth.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Jawaharlal Nehru Medical College Wardha in 2025",
      keyPoints: [
        "Comprehensive curriculum that integrates theory and practice.",
        "Modern infrastructure and advanced medical equipment.",
        "Experienced faculty members who provide mentorship and support.",
        "Opportunities for hands-on training in affiliated hospitals.",
      ],
    },
    admissionProcess: {
      title:
        "Admission Process in Jawaharlal Nehru Medical College Wardha in 2025",
      data: [
        "Candidates must submit their NEET UG scores and necessary documents.",
        "Shortlisted candidates will be selected for counseling or interviews.",
        "The college provides guidance throughout the admission process.",
      ],
    },
    whyChoose: {
      title:
        "Why Choose Jawaharlal Nehru Medical College Wardha Admission in 2025",
      data: [
        "Established as a leading medical college with over 30 years of excellence in medical education.",
        "Offers comprehensive MBBS, MD/MS, and super-specialization programs, all recognized by the Medical Council of India.",
        "Access to Acharya Vinobha Bhave Rural Hospital, a 2500+ bedded teaching hospital providing diverse clinical opportunities.",
        "State-of-the-art infrastructure including E-classrooms, Virtual Learning Centre, Clinical Skill Lab, and Digital Library.",
        "Focus on innovative, learner-centric teaching methods like Problem-Based Learning and Integrated Teaching.",
        "Opportunities for hands-on learning and research with a dedicated Central Research Laboratory and Animal Research Lab.",
        "Student support services such as academic counseling, mental health support, career guidance, and wellness programs.",
        "Rich campus life with extracurricular activities, clubs, and societies promoting personal and professional growth.",
        "Located in a lush, eco-friendly 125-acre campus, providing a peaceful and conducive environment for learning.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Jawaharlal Nehru Medical College Wardha in 2025",
      keyPoints: [
        "Completion of 10+2 or equivalent with 50% marks in Physics, Chemistry, Biology, and English.",
        "A valid NEET UG score is required.",
        "Candidates must be at least 17 years old by the time of admission.",
        "Submission of academic documents and NEET scorecards.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents Admission in Jawaharlal Nehru Medical College Wardha in 2025",
      keyPoints: [
        "Mark sheets of 10th and 12th grade or equivalent.",
        "NEET UG scorecard.",
        "Proof of identity and recent passport-sized photographs.",
        "Transfer certificate and migration certificate (if applicable).",
        "Category and income certificates (if applicable).",
      ],
    },
  },
  {
    collegeName: "Acs Medical College and Hospital",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/acs-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/acs-2.webp",
    ],
    collegeData: [
      "ACS Medical College and Hospital, established in 2007 under Dr. MGR Educational and Research Institute, is a private medical institute located in Chennai, Tamil Nadu. It is affiliated with Dr. MGR Educational and Research Institute and approved by the Medical Council of India (MCI). The college offers both undergraduate and postgraduate medical programs.",
      "The institute is equipped with state-of-the-art facilities, including well-equipped laboratories, skill labs, and spacious classrooms. The 700-bed hospital provides comprehensive medical and surgical services, along with 24/7 laboratory facilities. ACSMC is committed to community health services through its rural and urban health training centers and offers healthcare services under various government schemes.",
      "ACS Medical College and Hospital is accredited by NABL, NABH, ISO, and NAAC with an 'A+' grade. The institute offers MBBS at the UG level and MD/MS at the PG level in various medical disciplines. The application process is conducted online, and the college provides an environment for both academic and extracurricular activities, including cultural events and sports.",
    ],
    collegeAdvantage: {
      title: "Advantages Of Taking Admission in ACS Medical College in 2025",
      keyPoints: [
        "State-of-the-art facilities with a well-equipped 700-bed hospital providing comprehensive medical and surgical services.",
        "Accredited by NABL, NABH, ISO, and NAAC with 'A+' grade, ensuring high educational and healthcare standards.",
        "Offers both undergraduate (MBBS) and postgraduate (MD/MS) medical programs in various medical disciplines.",
        "Strong focus on community health services through rural and urban health training centers, benefiting local populations.",
        "Well-equipped laboratories, skill labs, museum, and spacious classrooms enhancing the learning environment.",
        "Opportunities for cultural and sports activities, fostering holistic development of students.",
        "Online application process for easy and convenient admissions.",
        "Provides healthcare under various state and central government schemes, making healthcare more accessible.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in ACS Medical College in 2025",
      data: [
        "Candidates need to apply through the official website, providing NEET UG scores and necessary documents.",
        "Candidate selection is based on the fulfillment of eligibility criteria and merit.",
        "Advisors will assist students throughout the admission procedure to ensure a smooth process.",
      ],
    },
    whyChoose: {
      title: "Why Choose ACS Medical College Admission in 2025",
      data: [
        "Comprehensive education model integrating theory, research, and clinical exposure.",
        "Well-established college infrastructure and facilities.",
        "Highly experienced faculty and a focus on student success.",
        "Strong hospital affiliations for hands-on clinical experience.",
      ],
    },
    eligibility: {
      title: "Eligibility Criteria Admission in ACS Medical College in 2025",
      keyPoints: [
        "Completion of 10+2 with at least 50% marks in Physics, Chemistry, Biology, and English.",
        "Valid NEET UG score is a must for all candidates.",
        "Candidates must be at least 17 years old by the date of admission.",
        "Candidates should submit the necessary academic documents and NEET scorecard.",
      ],
    },
    requireDocuments: {
      title: "Required Documents Admission in ACS Medical College in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "NEET UG scorecard.",
        "Proof of identity and passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate (if applicable).",
        "income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Bhaarath Medical College Chennai",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/bhaarath-college-1.webp",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/bhaarath-college-2.webp",
    ],
    collegeData: [
      "Bharath Institute of Higher Education and Research (BIHER) was established under the Sri Lakshmi Ammal Educational Trust, with the goal of offering advanced knowledge in Science and Technology. The institute, which started in 1984, became a deemed-to-be university in 2003, accredited by the University Grants Commission (UGC).",
      "Sri Lakshmi Ammal Educational Trust Founded in 1984 with aims to establish top-tier centres of professional education in various disciplines. The Trust’s mission is to provide young men and women with the opportunity to pursue professional courses, transforming them into skilled professionals with exemplary qualities dedicated to serving society.",
      "For over 39 years, BIHER has been dedicated to providing high-quality education, aiming to produce competent and successful professionals. Many of its alumni hold prestigious positions in India and abroad, contributing to various sectors across the globe.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Bhaarath Medical College in 2025",
      keyPoints: [
        "Modern infrastructure with state-of-the-art medical facilities.",
        "Comprehensive curriculum focusing on both theoretical and practical learning.",
        "Clinical training at affiliated hospitals with diverse patient populations.",
        "Research-oriented education with opportunities for student engagement.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Bhaarath Medical College in 2025",
      data: [
        "Candidates need to apply through the official website, providing NEET UG scores and necessary documents.",
        "Candidate selection is based on the fulfillment of eligibility criteria and merit.",
        "Advisors will assist students throughout the admission procedure to ensure a smooth process.",
      ],
    },
    whyChoose: {
      title: "Why Choose Bhaarath Medical College Admission in 2025",
      data: [
        "Established under the Sri Lakshmi Ammal Educational Trust, Bharath Institute of Higher Education and Research (BIHER) has a legacy of over 39 years in providing quality education.",
        "BIHER has been granted the status of a Deemed-to-be-University by the University Grants Commission (UGC), which ensures the credibility and recognition of the degrees offered.",
        "The institute offers advanced programs in various fields like Science, Technology, and Healthcare, with a focus on transforming students into skilled professionals ready for global careers.",
        "With a rich history of success, BIHER alumni are well-established in responsible positions across industries in both India and abroad, reflecting the high caliber of its education.",
        "The institute provides a wide range of undergraduate and postgraduate courses, promoting career-oriented and practical education to meet the demands of modern industries.",
        "BIHER focuses on holistic development, offering students not just academic excellence but also opportunities for personal growth and social responsibility through its values-driven education.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Bhaarath Medical College in 2025",
      keyPoints: [
        "Completion of 10+2 with at least 50% marks in Physics, Chemistry, Biology, and English.",
        "Valid NEET UG score is a must for all candidates.",
        "Candidates must be at least 17 years old by the date of admission.",
        "Candidates should submit the necessary academic documents and NEET scorecard.",
      ],
    },
    requireDocuments: {
      title: "Required Documents Admission in Bhaarath Medical College in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "NEET UG scorecard.",
        "Proof of identity and passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate (if applicable).",
        "income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Sree Balaji Medical College Hospital",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/shree-balaji-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/shree-balaji-2.webp",
    ],
    collegeData: [
      "Sree Balaji Medical College and Hospital, established in 2004, is a constituent college under Bharath Institute of Higher Education and Research (BIHER) and is managed by Sri Lakshmi Ammal Educational Trust. The college operates a 1,190-bed in-patient hospital, offering comprehensive healthcare services along with medical education.",
      "The college is accredited by NABL and approved by ICMR and the Medical Council of India (MCI). It is also ISO certified, ensuring quality standards in both education and healthcare services. Sree Balaji Medical College & Hospital provides a wide range of undergraduate, postgraduate, and diploma courses through its four departments and 30 sub-departments in various medical disciplines.",
      "Since its inception in 2003, the college has grown significantly. It started with an intake of 100 MBBS students annually and expanded to offer postgraduate courses in 2009. Currently, the college admits 250 MBBS students annually and 163 postgraduate and super-specialty students across various disciplines, offering a diverse range of opportunities in the medical field.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Sree Balaji Medical College in 2025",
      keyPoints: [
        "Modern infrastructure with state-of-the-art medical facilities.",
        "Comprehensive curriculum focusing on both theoretical and practical learning.",
        "Clinical training at affiliated hospitals with diverse patient populations.",
        "Research-oriented education with opportunities for student engagement.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Sree Balaji Medical College in 2025",
      data: [
        "Candidates need to apply through the official website, providing NEET UG scores and necessary documents.",
        "Candidate selection is based on the fulfillment of eligibility criteria and merit.",
        "Advisors will assist students throughout the admission procedure to ensure a smooth process.",
      ],
    },
    whyChoose: {
      title: "Why Choose Sree Balaji Medical College Admission in 2025",
      data: [
        "Comprehensive education model integrating theory, research, and clinical exposure.",
        "Well-established college infrastructure and facilities.",
        "Highly experienced faculty and a focus on student success.",
        "Strong hospital affiliations for hands-on clinical experience.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Sree Balaji Medical College in 2025",
      keyPoints: [
        "Completion of 10+2 with at least 50% marks in Physics, Chemistry, Biology, and English.",
        "Valid NEET UG score is a must for all candidates.",
        "Candidates must be at least 17 years old by the date of admission.",
        "Candidates should submit the necessary academic documents and NEET scorecard.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents Admission in Sree Balaji Medical College in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "NEET UG scorecard.",
        "Proof of identity and passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate (if applicable).",
        "income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Sri Ramachandra Medical College",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/ramchandra-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/ramchandra-2.jpg",
    ],
    collegeData: [
      "Sri Ramachandra Institute of Higher Education and Research (SRIHER), located in Porur, Chennai, was established in 1985 by the Sri Ramachandra Educational and Health Trust. Initially known as Sri Ramachandra University, it is recognized by UGC and is a member of the AIU. SRIHER is accredited by NABH and has received an ‘A++’ grade from NAAC. The institute was declared a Deemed University in 1994 by the Government of India and is home to the Sri Ramachandra Medical Centre, which was accredited by the Joint Commission International (JCI) in 2018.",
      "SRIHER Chennai is ranked 55th in the University category and 20th in Medical by NIRF Rankings 2024. The institute offers a diverse range of full-time courses including Physiotherapy, Allied Health Sciences, Biomedical Sciences, Management, BTech, BDS, BPT, BASLP, MBBS, MD, and MDS. Admission to MBBS is based on NEET scores, while NEET PG and NEET MDS scores are required for MD and MDS programs.",
      "The campus of SRIHER is equipped with modern facilities such as a library, auditorium, seminar halls, guest houses, internet and Wi-Fi access, hostels, and a canteen. The institution has earned recognition from the WHO as a Collaborating Centre for Research and Training in Occupational Health, and it is also a Nodal Centre for Medical Education Technology courses as recognized by the Medical Council of India.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Sri Ramachandra Medical College in 2025",
      keyPoints: [
        "Accredited by NABH and NAAC with an ‘A++’ grade, ensuring high-quality education and healthcare standards.",
        "Offers a wide range of full-time courses in various disciplines including MBBS, MD, MDS, BDS, Physiotherapy, Allied Health Sciences, Biomedical Sciences, and Management.",
        "Ranked 55th in University and 20th in Medical by NIRF Rankings 2024, highlighting the institute’s academic excellence.",
        "Affiliated with the Sri Ramachandra Medical Centre, a JCI-accredited hospital, providing students with practical exposure to a multi-specialty healthcare environment.",
        "Recognized as a WHO Collaborating Centre for Research and Training in Occupational Health, ensuring global research exposure for students.",
        "Well-equipped campus with modern amenities such as a digital library, seminar halls, hostels, Wi-Fi, and guest houses to support student learning and life.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Sri Ramachandra Medical College in 2025",
      data: [
        "Candidates need to apply through the official website, providing NEET UG scores and necessary documents.",
        "Candidate selection is based on the fulfillment of eligibility criteria and merit.",
        "Advisors will assist students throughout the admission procedure to ensure a smooth process.",
      ],
    },
    whyChoose: {
      title: "Why Choose Sri Ramachandra Medical College Admission in 2025",
      data: [
        "Comprehensive education model integrating theory, research, and clinical exposure.",
        "Well-established college infrastructure and facilities.",
        "Highly experienced faculty and a focus on student success.",
        "Strong hospital affiliations for hands-on clinical experience.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Sri Ramachandra Medical College in 2025",
      keyPoints: [
        "Completion of 10+2 with at least 50% marks in Physics, Chemistry, Biology, and English.",
        "Valid NEET UG score is a must for all candidates.",
        "Candidates must be at least 17 years old by the date of admission.",
        "Candidates should submit the necessary academic documents and NEET scorecard.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents Admission in Sri Ramachandra Medical College in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "NEET UG scorecard.",
        "Proof of identity and passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate (if applicable).",
        "income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Sri Lalithambigai Medical College",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/shri-lalita-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/shri-lalita-2.jpg",
    ],
    collegeData: [
      "Sri Lalithambigai Medical College and Hospital, located in Chennai, Tamil Nadu, is a prestigious educational institution known for its quality education and medical services. The institution offers a wide variety of undergraduate degree programs, with a focus on Medicine and Health Sciences. It is recognized for providing NMC-approved courses, which are available in full-time mode, allowing students to build a solid foundation in their respective fields.",
      "The hospital is equipped with advanced infrastructure and innovative medical facilities, ensuring top-notch patient care. It offers a wide range of services, including 24/7 outpatient and inpatient care, with 330 beds available. The hospital specializes in multiple surgical disciplines, such as General Surgery, Orthopedics, ENT Surgery, and Pediatric Surgery, supported by well-equipped ICUs, ICCUs, SICUs, PICUs, NICUs, and IMCUs. Additionally, the hospital provides comprehensive laboratory, radiology, ultrasound, pharmacy, and blood bank services.",
      "Sri Lalithambigai Medical College is dedicated to providing high-quality education at affordable fees, making it accessible to talented students. The institution is home to a team of experienced and professional faculty members who play a vital role in offering valuable education. The college aims to nurture the next generation of medical professionals and researchers through its rigorous academic programs, ensuring that students are well-prepared for their careers in healthcare.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Sri Lalithambigai Medical College in 2025",
      keyPoints: [
        "State-of-the-art infrastructure with innovative medical facilities.",
        "Comprehensive healthcare services under one roof, including 24/7 outpatient and inpatient care.",
        "Specialized surgical services in General Surgery, Orthopedics, ENT Surgery, Pediatric Surgery, etc.",
        "Well-equipped ICUs, ICCUs, SICUs, PICUs, NICUs, and IMCUs for critical care.",
        "Advanced laboratory, radiology, ultrasound, pharmacy, and blood bank services available round-the-clock.",
        "Experienced and professional faculty members dedicated to providing high-quality education.",
        "Affordable education with NMC-approved programs in Medicine and Health Sciences.",
        "A focus on practical and hands-on learning to prepare students for real-world healthcare challenges.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Sri Lalithambigai Medical College in 2025",
      data: [
        "Candidates need to apply through the official website, providing NEET UG scores and necessary documents.",
        "Candidate selection is based on the fulfillment of eligibility criteria and merit.",
        "Advisors will assist students throughout the admission procedure to ensure a smooth process.",
      ],
    },
    whyChoose: {
      title: "Why Choose Sri Lalithambigai Medical College Admission in 2025",
      data: [
        "Comprehensive education model integrating theory, research, and clinical exposure.",
        "Well-established college infrastructure and facilities.",
        "Highly experienced faculty and a focus on student success.",
        "Strong hospital affiliations for hands-on clinical experience.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Sri Lalithambigai Medical College in 2025",
      keyPoints: [
        "Completion of 10+2 with at least 50% marks in Physics, Chemistry, Biology, and English.",
        "Valid NEET UG score is a must for all candidates.",
        "Candidates must be at least 17 years old by the date of admission.",
        "Candidates should submit the necessary academic documents and NEET scorecard.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents Admission in Sri Lalithambigai Medical College in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "NEET UG scorecard.",
        "Proof of identity and passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate (if applicable).",
        "income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Mahatma Gandhi Medical College and Research Institute",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/gandhi-college-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/gandhi-college-2.jpg",
    ],
    collegeData: [
      "Mahatma Gandhi Institute of Medical Sciences (MGIMS) was Founded in 1969, in Sevagram, Maharashtra, stands as a premier medical institution. Affiliated with the Maharashtra University of Health Sciences in Nashik, the institute is named after the Father of the Nation, Mahatma Gandhi, and aligns with his values of service and social upliftment. MGIMS offers a holistic approach to medical education, preparing students to serve society with empathy and dedication.",
      "The MBBS program at MGIMS spans five and a half years, including a year-long internship, ensuring a comprehensive learning experience. The curriculum integrates both theoretical learning and practical training to foster critical thinking, clinical expertise, and ethical values. With state-of-the-art facilities, including well-equipped labs, lecture halls, and the Kasturba Hospital for hands-on clinical exposure, MGIMS provides an ideal environment for aspiring doctors to develop their skills.",
      "MGIMS prides itself on its highly qualified and experienced faculty who provide exceptional mentorship and guidance to students. The institution also places significant emphasis on community service and social responsibility, encouraging students to engage with underserved populations through outreach programs. Admission to MGIMS is competitive, based on NEET or AIPMT scores, and the institute ensures a personalized learning environment with limited seats and financial aid options for deserving students.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Mahatma Gandhi Medical College and Research Institute in 2025",
      keyPoints: [
        "Holistic approach to medical education, integrating theoretical knowledge with practical training.",
        "State-of-the-art infrastructure including well-equipped laboratories, modern lecture halls, and advanced medical technology.",
        "Hands-on clinical exposure through Kasturba Hospital, offering real-world experience under expert guidance.",
        "Highly qualified and experienced faculty committed to academic excellence and mentorship.",
        "Emphasis on community engagement and social responsibility, with outreach programs targeting underserved populations.",
        "Personalized attention due to a limited number of seats, ensuring a conducive learning environment.",
        "Strong focus on research and innovation, encouraging students to explore new frontiers in medicine.",
        "Competitive admission process through NEET or AIPMT, ensuring selection of the most deserving candidates.",
        "Scholarships and financial aid options available to support deserving students from diverse backgrounds.",
        "Graduates emerge as competent, compassionate, and socially responsible healthcare professionals.",
      ],
    },
    admissionProcess: {
      title:
        "Admission Process in Mahatma Gandhi Medical College and Research Institute in 2025",
      data: [
        "Candidates must appear for the All India Pre-Medical Test (AIPMT) or the National Eligibility-cum-Entrance Test (NEET).",
        "Candidates must meet the eligibility criteria set by the institute for admission.",
        "Shortlisted candidates are invited to participate in a counseling process.",
        "Final selections are based on merit and availability of seats.",
      ],
    },
    whyChoose: {
      title:
        "Why Choose Mahatma Gandhi Medical College and Research Institute Admission in 2025",
      data: [
        "Comprehensive education model integrating theory, research, and clinical exposure.",
        "Well-established college infrastructure and facilities.",
        "Highly experienced faculty and a focus on student success.",
        "Strong hospital affiliations for hands-on clinical experience.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Mahatma Gandhi Medical College and Research Institute in 2025",
      keyPoints: [
        "Completion of 10+2 with at least 50% marks in Physics, Chemistry, Biology, and English.",
        "Admission to MGIMS is competitive, based on NEET or AIPMT scores.",
        "Candidates must be at least 17 years old by the date of admission.",
        "Candidates should submit the necessary academic documents and NEET scorecard.",
        "Candidates should be Indian citizens or meet eligibility requirements for foreign students, if applicable.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents Admission in Mahatma Gandhi Medical College and Research Institute in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "NEET or AIPMT scorecard.",
        "Proof of identity and passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate (if applicable).",
        "income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Bangalore Medical College and Research Institute",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/banglore-medical-college-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/banglore-medical-college-2.jpg",
    ],
    collegeData: [
      "Bangalore Medical College and Research Institute (BMCRI) was established in 1955 as a private medical school by the Mysore Education Society. The founders of the society, Dr. R. Shivaram, Dr. Mekhri, Dr. B.K. Narayana Rao, and Dr. B.V. Ramaswamy, played a pivotal role in its formation. The college was later transferred to the Government of Mysore in 1957, initially affiliated with Mysore University, and later with Bangalore University. Since 1996, it has been affiliated with Rajiv Gandhi University of Health Sciences, offering a wide range of undergraduate and postgraduate medical programs.",
      "BMCRI holds Medical Council of India (MCI) recognition for admitting 250 undergraduate students and 280 postgraduate students across various disciplines. These include MS and MD courses in Anatomy, Physiology, Biochemistry, Pharmacology, Pathology, Microbiology, and numerous other specialties. The college also offers 16 super-specialty seats in fields like Plastic Surgery, Paediatric Surgery, Neurology, Surgical Gastroenterology, and Cardiology. Additionally, BMCRI provides fellowship programs in various specialized areas, along with Bachelors’ degrees in Nursing and Allied Health Sciences and multiple diploma and certificate courses in paramedical fields.",
      "The institute boasts excellent infrastructure, including spacious classrooms, well-equipped laboratories, skill labs, museums, a library, and auditoriums. Students receive thorough academic training and are given ample opportunities for hands-on practice. The college also emphasizes the importance of extracurricular activities, encouraging students to engage in cultural, sports, and other co-curricular activities. BMCRI is committed to producing ethical, competent doctors who can deliver high-quality healthcare services to the community.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Bangalore Medical College and Research institute in 2025",
      keyPoints: [
        "Established medical infrastructure and state-of-the-art facilities.",
        "Comprehensive training program combining classroom learning with hands-on clinical practice.",
        "Affiliated hospitals offering practical medical experience and exposure to diverse patient populations.",
        "Highly qualified faculty with expertise in various medical specialties.",
      ],
    },
    admissionProcess: {
      title:
        "Admission Process in Bangalore Medical College and Research institute in 2025",
      data: [
        "Candidates must submit their NEET UG score and required documents for admission.",
        "Selection is based on merit, with counseling sessions organized for shortlisted candidates.",
        "BMCRI offers full support during the admission process to guide candidates.",
      ],
    },
    whyChoose: {
      title:
        "Why Choose Bangalore Medical College and Research institute Admission in 2025",
      data: [
        "Well-established institution with a long history since 1955.",
        "Affiliated with Rajiv Gandhi University of Health Sciences and MCI-recognized for undergraduate and postgraduate programs.",
        "Wide range of undergraduate and postgraduate medical courses, including super-specialty programs in Plastic Surgery, Paediatric Surgery, Neurology, and more.",
        "Fellowship programs in specialized fields such as Vitreo-Retina, Surgical Gastroenterology, Neurology, and Pediatric Dermatology.",
        "Offers Bachelor’s degrees in Nursing and Allied Health Sciences, along with diploma and certificate courses in paramedical streams.",
        "State-of-the-art infrastructure with spacious classrooms, well-equipped laboratories, skill labs, museums, library, and auditoriums.",
        "Strong focus on hands-on training and academic excellence.",
        "Encourages students to participate in extracurricular activities like sports, cultural events, and co-curricular activities.",
        "Commitment to nurturing ethical, competent, and well-trained doctors who contribute to high-quality healthcare services.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Bangalore Medical College and Research institute in 2025",
      keyPoints: [
        "10+2 or equivalent with a minimum of 50% marks in Physics, Chemistry, Biology, and English.",
        "A valid NEET UG score is required.",
        "Candidates must be at least 17 years of age by the date of admission.",
        "Relevant academic and NEET documents must be submitted.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents Admission in Bangalore Medical College and Research institute in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "NEET UG scorecard.",
        "Proof of identity and recent passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate and income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Ramaiah Medical College",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/ramaiah-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/ramaiah-2.webp",
    ],
    collegeData: [
      "Ramaiah Medical College (RMC), established in 1979, was founded with the mission of providing high-quality medical education. The college became a Constituent College of Ramaiah University of Applied Sciences (RUAS) from the academic year 2022-23 and is recognized by the National Medical Commission (NMC). This recognition reflects the institution's commitment to offering world-class medical education and training.",
      "In 1984, the Ramaiah Medical College Hospital (RMCH) was established on the college campus to support the training of future doctors. The hospital has a capacity of 1,380 beds and provides a wide range of services, including both broad specialties and super specialties, ensuring that students receive comprehensive clinical experience. The college also features modern infrastructure, technology-enabled classrooms, advanced laboratories, and a focus on integrating teaching, clinical practice, and research.",
      "RMC strives to develop well-rounded professionals who are not only equipped with a strong foundation of medical knowledge but also excel in extracurricular activities. Students are trained in both urban and rural settings, gaining hands-on experience and learning to make independent decisions while collaborating with peers from other healthcare disciplines such as nursing, physiotherapy, and pharmacy. The college encourages students to actively participate in co-curricular activities, fostering leadership skills and the qualities necessary for future medical leaders.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Ramaiah Medical College in 2025",
      keyPoints: [
        "Established in 1979 with a mission to provide high-quality medical education.",
        "Constituent College of Ramaiah University of Applied Sciences (RUAS) since 2022-23.",
        "Recognized by the National Medical Commission (NMC) for undergraduate and postgraduate programs.",
        "Ramaiah Medical College Hospital (RMCH) with 1,380 beds, offering broad specialty and super specialty services.",
        "Modern infrastructure with technology-enabled classrooms and advanced laboratories.",
        "Strong focus on both academic excellence and practical clinical experience.",
        "Students gain hands-on experience in urban and rural settings, preparing them for real-world healthcare challenges.",
        "Encourages co-curricular and extracurricular activities to develop leadership skills in students.",
        "Interdisciplinary collaboration with students from nursing, physiotherapy, and pharmacy programs.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Ramaiah Medical College in 2025",
      data: [
        "Candidates must submit their NEET UG score and required documents for admission.",
        "Selection is based on merit, with counseling sessions organized for shortlisted candidates.",
        "BMCRI offers full support during the admission process to guide candidates.",
      ],
    },
    whyChoose: {
      title: "Why Choose Ramaiah Medical College Admission in 2025",
      data: [
        "Strong reputation for excellence in medical education and clinical training.",
        "Robust infrastructure and hospital affiliations that offer hands-on learning.",
        "Skilled faculty dedicated to students' growth and success.",
        "Opportunities for research and development in the medical field.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Ramaiah Medical College in 2025",
      keyPoints: [
        "10+2 or equivalent with a minimum of 50% marks in Physics, Chemistry, Biology, and English.",
        "A valid NEET UG score is required.",
        "Candidates must be at least 17 years of age by the date of admission.",
        "Relevant academic and NEET documents must be submitted.",
      ],
    },
    requireDocuments: {
      title: "Required Documents Admission in Ramaiah Medical College in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "NEET UG scorecard.",
        "Proof of identity and recent passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate and income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Dr Bhim Rao Ambedkar Medical College",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/bhim-rao-1.png",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/bhim-rao-2.jpg",
    ],
    collegeData: [
      "Dr Bhim Rao Ambedkar Medical College is a leading private institution offering high-quality medical education at both undergraduate and postgraduate levels. The college provides an MBBS program with an annual intake of 150 students and admits 74 students annually for various postgraduate broad specialty courses. All programs are recognized by the National Medical Commission, and the college campus includes the hospital, student hostels, and faculty residences, all within a secure environment.",
      "In July 1980, the Ananda Social and Educational Trust established a medical college named after the respected Bharatha Rathna Dr. B.R. Ambedkar. The formation of this Trust was the realization of a vision shared by dedicated intellectuals and philanthropists who aimed to uplift underprivileged communities by providing quality education, enabling them to rise within the social hierarchy.",
      "The college prides itself on its modern infrastructure, esteemed faculty, and comprehensive support systems for students and staff. The teaching environment fosters the holistic development of students. With advancements in medical education and healthcare, the focus is on preparing students to be competent, self-reliant, and lifelong learners. The college also emphasizes student-centric learning, advanced teaching techniques, and active participation in co-curricular and extracurricular activities.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Dr Bhim Rao Ambedkar Medical College in 2025",
      keyPoints: [
        "Established in 1980 with a vision to uplift underprivileged communities through quality education.",
        "Recognized by the National Medical Commission (NMC) for both undergraduate and postgraduate medical programs.",
        "Offers MBBS with an annual intake of 150 students and various postgraduate broad specialty courses with 74 seats.",
        "State-of-the-art multispecialty teaching hospital equipped with modern diagnostic and therapeutic facilities.",
        "Well-equipped laboratories, smart lecture halls, and an outstanding library with national and international journals.",
        "Experienced and dedicated faculty trained in advanced teaching technologies.",
        "Focus on student-centric learning, emphasizing understanding, analysis, and application of knowledge.",
        "Holistic development of students through a strong emphasis on academics, co-curricular, and extracurricular activities.",
        "Secure campus with college, hospital, hostels, and faculty residential quarters all within close proximity.",
      ],
    },
    admissionProcess: {
      title:
        "Admission Process in Dr Bhim Rao Ambedkar Medical College in 2025",
      data: [
        "Candidates must submit their NEET UG score and required documents for admission.",
        "Selection is based on merit, with counseling sessions organized for shortlisted candidates.",
        "BMCRI offers full support during the admission process to guide candidates.",
      ],
    },
    whyChoose: {
      title:
        "Why Choose Dr Bhim Rao Ambedkar Medical College Admission in 2025",
      data: [
        "Legacy of excellence in medical education with over 40 years of experience since its establishment in 1980.",
        "High-quality education recognized by the National Medical Commission (NMC) for both undergraduate and postgraduate courses.",
        "State-of-the-art infrastructure, including a multispecialty teaching hospital with modern facilities for hands-on experience.",
        "Experienced and dedicated faculty members trained in advanced teaching methodologies to provide the best learning environment.",
        "Comprehensive student support systems to ensure both academic and personal development.",
        "Holistic development focus with a blend of rigorous academics, co-curricular, and extracurricular activities.",
        "Secure campus with integrated facilities, including student hostels and faculty residences, all within close proximity.",
        "Strong emphasis on student-centric learning, fostering critical thinking, analysis, and real-world application of knowledge.",
        "Opportunities for students to be involved in medical research, innovation, and community health initiatives.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Dr Bhim Rao Ambedkar Medical College in 2025",
      keyPoints: [
        "10+2 or equivalent with a minimum of 50% marks in Physics, Chemistry, Biology, and English.",
        "A valid NEET UG score is required.",
        "Candidates must be at least 17 years of age by the date of admission.",
        "Relevant academic and NEET documents must be submitted.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents Admission in Dr Bhim Rao Ambedkar Medical College in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "NEET UG scorecard.",
        "Proof of identity and recent passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate and income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Vydehi Medical College Bangalore",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/vydehi-1.webp",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/vydehi-2.png",
    ],
    collegeData: [
      "The Vydehi Institute of Medical Sciences and Research Centre (VIMS&RC) is a prominent institution in India, known for offering world-class medical education alongside state-of-the-art healthcare services. The institute is committed to developing a robust medical ecosystem and producing the country’s top medical professionals. VIMS&RC focuses on delivering quality education through an integrated curriculum that combines modern technology and research, providing students with outstanding infrastructure, highly trained faculty, and excellent clinical facilities.",
      "VIMS&RC offers a wide range of comprehensive courses in fields such as Medicine, Dentistry, Nursing, Physiotherapy, Pharmacy, and Allied Health Sciences, allowing students to choose their preferred career path. The institute has become a leader in medical education, combining advanced academic programs with clinical practice. The goal of Vydehi Institutions extends beyond education, aiming to improve the lives of people, especially in rural areas, through healthcare and community support.",
      "The core values at VIMS&RC are patient-centric care, humility, dignity, ethical practices, and innovation. The institute integrates compassionate care with scientific knowledge to deliver the best patient outcomes, treating everyone with respect regardless of background. VIMS&RC is committed to providing transparency in healthcare services, involving patients in their treatment decisions, and continuously innovating with cutting-edge technology to offer affordable healthcare solutions.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Vydehi Medical College Bangalore in 2025",
      keyPoints: [
        "World-class medical education and state-of-the-art healthcare services.",
        "Focus on creating a strong medical ecosystem by producing top medical professionals.",
        "Integrated curriculum combining modern technology and research for quality education.",
        "Outstanding infrastructure with highly trained faculty, advanced laboratories, and clinical facilities.",
        "Wide range of courses in Medicine, Dentistry, Nursing, Physiotherapy, Pharmacy, and Allied Health Sciences.",
        "Commitment to improving healthcare in rural areas through community service and healthcare cooperation.",
        "Patient-centric approach with a focus on compassionate care and comfort for patients and their families.",
        "Strong ethical practices ensuring transparency and patient involvement in decision-making.",
        "Emphasis on innovation, utilizing cutting-edge technology to provide affordable healthcare solutions.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Vydehi Medical College Bangalore in 2025",
      data: [
        "Candidates must submit their NEET UG score and required documents for admission.",
        "Selection is based on merit, with counseling sessions organized for shortlisted candidates.",
        "BMCRI offers full support during the admission process to guide candidates.",
      ],
    },
    whyChoose: {
      title: "Why Choose Vydehi Medical College Bangalore Admission in 2025",
      data: [
        "Strong reputation for excellence in medical education and clinical training.",
        "Robust infrastructure and hospital affiliations that offer hands-on learning.",
        "Skilled faculty dedicated to students' growth and success.",
        "Opportunities for research and development in the medical field.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Vydehi Medical College Bangalore in 2025",
      keyPoints: [
        "10+2 or equivalent with a minimum of 50% marks in Physics, Chemistry, Biology, and English.",
        "A valid NEET UG score is required.",
        "Candidates must be at least 17 years of age by the date of admission.",
        "Relevant academic and NEET documents must be submitted.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents Admission in Vydehi Medical College Bangalore in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "NEET UG scorecard.",
        "Proof of identity and recent passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate and income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Niims Jaipur",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/nims-1.png",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/nims-2.webp",
    ],
    collegeData: [
      "NIMS University Rajasthan, Jaipur, is one of the largest self-financed universities in India, recognized for its exceptional quality of teaching and action-oriented research across various disciplines. Situated in the scenic Aravali hills along the Jaipur-Delhi Highway, the university offers state-of-the-art infrastructure and a sprawling campus that provides a conducive environment for academic growth. Established under the NIMS University Act, 2008, by the Government of Rajasthan, the university is recognized by the Government of India and various national bodies such as MCI, DCI, PCI, and AICTE.",
      "The university is on its way to becoming a leading center for education in a wide range of fields, from health sciences to engineering, humanities to multimedia, and more. NIMS University offers over 450 courses across multiple disciplines including Medical, Dental, Pharmacy, Paramedical, Physiotherapy, Nursing, Engineering, Management, Law, Architecture, and Arts. The university is also recognized by the UGC and various apex regulatory councils, and is a member of the Association of Indian Universities (AIU).",
      "Located in a picturesque setting, the campus attracts students from across India and around the world, creating a vibrant mix of cultures and offering international exposure. NIMS University not only focuses on academic excellence but also emphasizes the development of essential skills in its students, preparing them for professional success and encouraging them to strive for high professional and personal goals.",
    ],
    collegeAdvantage: {
      title: "Advantages Of Taking Admission in Niims Jaipur in 2025",
      keyPoints: [
        "One of the largest self-financed universities in India, recognized for its high-quality teaching and research.",
        "State-of-the-art infrastructure located in the picturesque Aravali hills along the Jaipur-Delhi Highway.",
        "Established under the NIMS University Act, 2008, recognized by the Government of Rajasthan and India.",
        "Offers over 450 courses in a wide range of disciplines including Medical, Dental, Pharmacy, Engineering, Management, Law, Arts, and more.",
        "Recognized by multiple national regulatory bodies like MCI, DCI, AICTE, PCI, and UGC.",
        "Vibrant campus life with students from across India and the world, providing a rich blend of cultures and international exposure.",
        "Member of the Association of Indian Universities (AIU), ensuring global recognition and standards.",
        "Focus on practical training and skill development in addition to academic excellence.",
        "Promotes interdisciplinary learning and offers opportunities for professional growth across multiple fields.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Niims Jaipur in 2025",
      data: [
        "Candidates must submit their NEET UG score and required documents for admission.",
        "Selection is based on merit, with counseling sessions organized for shortlisted candidates.",
        "BMCRI offers full support during the admission process to guide candidates.",
      ],
    },
    whyChoose: {
      title: "Why Choose Niims Jaipur Admission in 2025",
      data: [
        "Strong reputation for excellence in medical education and clinical training.",
        "Robust infrastructure and hospital affiliations that offer hands-on learning.",
        "Skilled faculty dedicated to students' growth and success.",
        "Opportunities for research and development in the medical field.",
      ],
    },
    eligibility: {
      title: "Eligibility Criteria Admission in Niims Jaipur in 2025",
      keyPoints: [
        "10+2 or equivalent with a minimum of 50% marks in Physics, Chemistry, Biology, and English.",
        "A valid NEET UG score is required.",
        "Candidates must be at least 17 years of age by the date of admission.",
        "Relevant academic and NEET documents must be submitted.",
      ],
    },
    requireDocuments: {
      title: "Required Documents Admission in Niims Jaipur in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "NEET UG scorecard.",
        "Proof of identity and recent passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate and income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Geetanjali Medical College Hospital",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/geetanjali-1.webp",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/geetanjali-2.jpg",
    ],
    collegeData: [
      "Geetanjali Medical College & Hospital (GMCH) is a premier medical institution under Geetanjali University, established through the vision of Shri J.P. Agarwal, the founder and chairman. His dedication has transformed GMCH into a trusted name in Udaipur and surrounding regions. With a focus on high-quality medical education, the institution ensures students receive a robust academic foundation and the skills required to become proficient healthcare professionals.",
      "GMCH is recognized by the Medical Council of India and the Government of India, with an annual intake of 250 MBBS students. The college provides a state-of-the-art healthcare environment designed for patient-friendly experiences. The hospital's strategic layout ensures easy access for patients and their families, ensuring efficient and timely emergency care. The college emphasizes high standards in education, clinical training, and the development of competent medical professionals.",
      "Geetanjali Medical College offers not only undergraduate education but also postgraduate courses in almost all medical departments, focusing on excellence in teaching and clinical training. The institution is also a hub for medical research, with over 100 ongoing research projects. The well-stocked central library boasts more than 50,000 books, journals, and e-journals, supporting students and faculty in their academic and research endeavors.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Geetanjali Medical College Hospital in 2025",
      keyPoints: [
        "Established medical infrastructure and state-of-the-art facilities.",
        "Comprehensive training program combining classroom learning with hands-on clinical practice.",
        "Affiliated hospitals offering practical medical experience and exposure to diverse patient populations.",
        "Highly qualified faculty with expertise in various medical specialties.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Geetanjali Medical College Hospital in 2025",
      data: [
        "Candidates must submit their NEET UG score and required documents for admission.",
        "Selection is based on merit, with counseling sessions organized for shortlisted candidates.",
        "BMCRI offers full support during the admission process to guide candidates.",
      ],
    },
    whyChoose: {
      title: "Why Choose Geetanjali Medical College Hospital Admission in 2025",
      data: [
        "Geetanjali Medical College & Hospital is recognized by the Medical Council of India and the Government of India, ensuring high standards in education and training.",
        "The institution boasts a strong reputation in Udaipur and surrounding regions, offering a trusted and patient-centered healthcare environment.",
        "With an annual intake of 250 MBBS students, the college provides excellent academic and clinical training with state-of-the-art infrastructure.",
        "Geetanjali Medical College has a proven track record of producing top-performing students, including university toppers and those securing top ranks in Rajasthan University of Health Sciences exams.",
        "The college offers both undergraduate and postgraduate courses, providing comprehensive medical education and clinical material for postgraduate training.",
        "A hub for medical research, with over 100 ongoing research projects, creating opportunities for students to engage in cutting-edge medical advancements.",
        "The central library is well-stocked with over 50,000 books, national and international journals, and e-journals, supporting academic excellence and research.",
        "The campus is designed with a patient-friendly approach, ensuring easy access to services and a hassle-free experience for both patients and their families during emergencies.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Geetanjali Medical College Hospital in 2025",
      keyPoints: [
        "10+2 or equivalent with a minimum of 50% marks in Physics, Chemistry, Biology, and English.",
        "A valid NEET UG score is required.",
        "Candidates must be at least 17 years of age by the date of admission.",
        "Relevant academic and NEET documents must be submitted.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents Admission in Geetanjali Medical College Hospital in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "NEET UG scorecard.",
        "Proof of identity and recent passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate and income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Kempegowda Institute Of Medical Science",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/kempegowda-medical-college-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/kempegowda-medical-college-2.jpg",
    ],
    collegeData: [
      "Kempegowda Institute of Medical Sciences (KIMS) was established in 1980 by the Vokkaligara Sangha and is affiliated with Rajiv Gandhi University of Health Sciences, Bangalore. The initiative for starting a medical college was officially taken by the Vokkaligara Sangha in September 1979, leading to the foundation stone being laid by Shri R. Gundu Rao, the Chief Minister of Karnataka, on 30th November 1980. The institute began its academic sessions on 16th March 1981, with Dr. M Basavaraju appointed as the first principal. KIMS Day is celebrated every year on this date.",
      "The college has grown over the years, celebrating its Silver Jubilee in 2006, and a new building was constructed to mark both the centenary celebrations of the Vokkaligara Sangha and the Silver Jubilee of KIMS. The pre-clinical and para-clinical departments are located near BDA Complex in Banashankari, while the clinical departments and KIMS Hospital are located 4 km away on Krishna Rajendra Road, Visveswarapuram.",
      "KIMS is recognized by several prestigious organizations, including the General Medical Council, United Kingdom, the World Health Organization, and the Royal College of Obstetrics and Gynaecologists, London. This recognition reflects the institute's commitment to maintaining international standards in medical education and healthcare.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Kempegowda Institute Of Medical Science in 2025",
      keyPoints: [
        "Established medical infrastructure and state-of-the-art facilities.",
        "Comprehensive training program combining classroom learning with hands-on clinical practice.",
        "Affiliated hospitals offering practical medical experience and exposure to diverse patient populations.",
        "Highly qualified faculty with expertise in various medical specialties.",
      ],
    },
    admissionProcess: {
      title:
        "Admission Process in Kempegowda Institute Of Medical Science in 2025",
      data: [
        "Candidates must submit their NEET UG score and required documents for admission.",
        "Selection is based on merit, with counseling sessions organized for shortlisted candidates.",
        "BMCRI offers full support during the admission process to guide candidates.",
      ],
    },
    whyChoose: {
      title:
        "Why Choose Kempegowda Institute Of Medical Science Admission in 2025",
      data: [
        "Strong reputation for excellence in medical education and clinical training.",
        "Robust infrastructure and hospital affiliations that offer hands-on learning.",
        "Skilled faculty dedicated to students' growth and success.",
        "Opportunities for research and development in the medical field.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Kempegowda Institute Of Medical Science in 2025",
      keyPoints: [
        "10+2 or equivalent with a minimum of 50% marks in Physics, Chemistry, Biology, and English.",
        "A valid NEET UG score is required.",
        "Candidates must be at least 17 years of age by the date of admission.",
        "Relevant academic and NEET documents must be submitted.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents Admission in Kempegowda Institute Of Medical Science in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "NEET UG scorecard.",
        "Proof of identity and recent passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate and income certificate (if applicable).",
      ],
    },
  },

  // Engineering College

  {
    collegeName: "Symbiosis Institute Of Technology",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/symbiosis-technology-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/symbiosis-technology-2.jpg",
    ],
    collegeData: [
      "Symbiosis Institute of Technology (SIT), established in 2008, is a premier engineering institute under Symbiosis International (Deemed University). It offers undergraduate B.Tech programs in various disciplines including Civil Engineering, Computer Science and Engineering, Electronics & Telecommunication Engineering, Mechanical Engineering, Artificial Intelligence & Machine Learning, and Robotics & Automation. The institute also offers postgraduate M.Tech programs in Artificial Intelligence & Machine Learning, Robotics & Automation, and Embedded Systems. It further provides a Ph.D. program in the Faculty of Engineering, which was introduced in 2010.",
      "SIT ensures a rigorous selection process for its B.Tech courses, admitting students based on SIT Engineering Entrance Exam (SIT EEE), JEE (Main), or other State Government Engineering Entrance Examinations. For M.Tech admissions, candidates must be GATE qualified. The institute has demonstrated an impressive placement record, with over 85% placement in 2021. Top companies such as Infosys, TCS, Capgemini, Amazon, Byjus, IBM, Accenture, Google, and Microsoft are among its key recruiters. The highest salary offered in 2021 was 12 LPA, with an average package ranging from 6.5 to 7 LPA.",
      "Symbiosis Institute of Technology is committed to providing quality education in line with the fast-evolving industry demands and technological advancements. The institute continually updates its curriculum to stay relevant to the dynamic job market. With a focus on innovative teaching methods and fostering overall student development through projects, tech fests, and cultural programs, SIT is steadily moving toward becoming one of India's top engineering institutes. The recruitment of qualified faculty and their ongoing professional development are core pillars of SIT’s strategy to nurture industry-ready graduates.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Symbiosis Institute of Technology in 2025",
      keyPoints: [
        "As part of the prestigious Symbiosis International University, SIT offers a high-quality education recognized both nationally and internationally.",
        "SIT offers a wide range of undergraduate and postgraduate programs in cutting-edge fields such as Artificial Intelligence & Machine Learning, Robotics & Automation, and Embedded Systems, along with traditional disciplines like Civil, Mechanical, and Computer Science Engineering.",
        "With a strong placement track record of over 85% in 2021, SIT's graduates are recruited by top multinational companies like Google, Amazon, Infosys, Accenture, and Microsoft, with attractive salary packages.",
        "The institute has highly qualified and experienced faculty who continuously upgrade their skills through conferences, research, and publications, ensuring students receive the most relevant and up-to-date education.",
        "SIT boasts modern classrooms, laboratories, and research facilities that foster a conducive learning environment. The campus is equipped with the latest technological tools to support both academic and extracurricular activities.",
        "The institute has strong industry ties and regularly updates its curriculum in consultation with industry experts, ensuring that students are well-prepared for the demands of the job market.",
        "SIT provides ample opportunities for students to engage in extracurricular activities like tech fests, cultural programs, and various student clubs, promoting holistic development alongside academic learning.",
        "With a Ph.D. program and a focus on research, SIT offers students the chance to engage in innovative projects and contribute to technological advancements.",
        "Being part of a globally recognized university, SIT provides students with exposure to international standards and opportunities for cross-border collaborations, internships, and academic exchanges.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Symbiosis Institute of Technology in 2025",
      data: [
        "Candidates Need to Select branch preferences during registration.",
        "Upload required documents, including entrance exam scorecard and category certificate (if applicable).",
        "Branch allocation is based on exam scores, preferences, and seat availability.",
        "Merit-listed candidates will receive an email with the offer letter and fee payment instructions within 5 days.",
      ],
    },
    whyChoose: {
      title: "Why Choose Symbiosis Institute of Technology Admission in 2025",
      data: [
        "Reputed for its strong industry connections and career opportunities for students.",
        "Highly qualified faculty members with expertise in various engineering disciplines.",
        "State-of-the-art infrastructure and a conducive learning environment.",
        "Active focus on innovation, research, and development, providing students with opportunities to grow beyond academics.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria for Admission in Symbiosis Institute of Technology in 2025",
      keyPoints: [
        "Candidates must have passed 10+2 or equivalent with a minimum of 45% marks in Physics, Chemistry, and Mathematics (40% for reserved category).",
        "A valid score in JEE Main is required.",
        "Candidates must be at least 17 years of age by the date of admission.",
        "Candidates who have appeared for JEE Main are eligible for admission to SIT.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents for Admission in Symbiosis Institute of Technology in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "JEE Main, SET or any government examination scorecard.",
        "Proof of identity and recent passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate and income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Symbiosis Skills and Professional University",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/sspu-1.png",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/sspu-2.jpg",
    ],
    collegeData: [
      "Symbiosis Skills and Professional University (SSPU), established in 1999, is a comes in best engineering institute under Symbiosis International (Deemed University). It offers undergraduate B.Tech programs in various disciplines including Civil Engineering, Computer Science and Engineering, Electronics & Telecommunication Engineering, Mechanical Engineering, Artificial Intelligence & Machine Learning, and Robotics & Automation. The institute also offers postgraduate M.Tech programs in Artificial Intelligence & Machine Learning, Robotics & Automation, and Embedded Systems. It further provides a Ph.D. program in the Faculty of Engineering, which was introduced in 2010.",
      "SIT ensures a rigorous selection process for its B.Tech courses, admitting students based on SSPU Engineering Entrance Exam (SSPU EEE), JEE (Main), or other State Government Engineering Entrance Examinations. For M.Tech admissions, candidates must be GATE qualified. The institute has demonstrated an impressive placement record, with over 85% placement in 2021. Top companies such as Infosys, TCS, Capgemini, Amazon, Byjus, IBM, Accenture, Google, and Microsoft are among its key recruiters. The highest salary offered in 2021 was 12 LPA, with an average package ranging from 6.5 to 7 LPA.",
      "Symbiosis Skills and Professional University is committed to providing quality education in line with the fast-evolving industry demands and technological advancements. The institute continually updates its curriculum to stay relevant to the dynamic job market. With a focus on innovative teaching methods and fostering overall student development through projects, tech fests, and cultural programs, SSPU is steadily moving toward becoming one of India's top engineering institutes. The recruitment of qualified faculty and their ongoing professional development are core pillars of SSPU strategy to nurture industry-ready graduates.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Symbiosis Skills and Professional University in 2025",
      keyPoints: [
        "As part of the prestigious Symbiosis International University, SSPU offers a high-quality education recognized both nationally and internationally.",
        "SSPU offers a wide range of undergraduate and postgraduate programs in cutting-edge fields such as Artificial Intelligence & Machine Learning, Robotics & Automation, and Embedded Systems, along with traditional disciplines like Civil, Mechanical, and Computer Science Engineering.",
        "With a strong placement track record of over 85% in 2021, SSPU's graduates are recruited by top multinational companies like Google, Amazon, Infosys, Accenture, and Microsoft, with attractive salary packages.",
        "The institute has highly qualified and experienced faculty who continuously upgrade their skills through conferences, research, and publications, ensuring students receive the most relevant and up-to-date education.",
        "SSPU boasts modern classrooms, laboratories, and research facilities that foster a conducive learning environment. The campus is equipped with the latest technological tools to support both academic and extracurricular activities.",
        "The institute has strong industry ties and regularly updates its curriculum in consultation with industry experts, ensuring that students are well-prepared for the demands of the job market.",
        "SSPU provides ample opportunities for students to engage in extracurricular activities like tech fests, cultural programs, and various student clubs, promoting holistic development alongside academic learning.",
        "With a Ph.D. program and a focus on research, SSPU offers students the chance to engage in innovative projects and contribute to technological advancements.",
        "Being part of a globally recognized university, SSPU provides students with exposure to international standards and opportunities for cross-border collaborations, internships, and academic exchanges.",
      ],
    },
    admissionProcess: {
      title:
        "Admission Process in Symbiosis Skills and Professional University in 2025",
      data: [
        "Candidates need to have valid JEE Main scores and apply online through the official portal.",
        "The selection process is merit-based, with counseling sessions for shortlisted candidates.",
        "The university offers assistance and guidance to ensure a smooth admission experience.",
      ],
    },
    whyChoose: {
      title:
        "Why Choose Symbiosis Skills and Professional University Admission in 2025",
      data: [
        "Focus on practical and skills-based learning.",
        "Strong industry partnerships to offer placement opportunities.",
        "Innovative programs that enhance employability.",
        "Modern infrastructure and professional development resources.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria for Admission in Symbiosis Skills and Professional University in 2025",
      keyPoints: [
        "Candidates must have passed 10+2 or equivalent with a minimum of 45% marks in Physics, Chemistry, and Mathematics (40% for reserved categories).",
        "A valid JEE Main score is required.",
        "Candidates must be at least 17 years old by the time of admission.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents for Admission in Symbiosis Skills and Professional University in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "JEE Main scorecard.",
        "Proof of identity and recent passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate and income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Bharati Vidyapeeth College Of Engineering",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/bharati-engineering-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/bharati-engineering-2.jpeg",
    ],
    collegeData: [
      "Bharati Vidyapeeth’s College of Engineering established in 1999, in New Delhi. It has strived to provide the best engineering education to its students through well-qualified and dedicated faculty and the provision of well-equipped modern labs. The college, affiliated to Guru Gobind Singh Indraprastha University, New Delhi, and approved by the All India Council for Technical Education (AICTE), Ministry of HRD, Govt. of India, is steadily progressing in its goal to establish itself among the top engineering colleges in North India. The vision of the college is to continuously excel and create awareness about emerging technologies, providing a platform for budding researchers to make their mark in the scientific community.",
      "Bharati Vidyapeeth College of Engineering (BVCE) is one of the prominent engineering colleges in India, offering undergraduate programs in various engineering disciplines. The 2025 admission process is based on the JEE Main score, followed by counseling sessions conducted by the college. BVCE offers a robust infrastructure with modern classrooms, laboratories, and a strong emphasis on industry collaboration for placements.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Bharati Vidyapeeth College of Engineering in 2025",
      keyPoints: [
        "Established college with a strong reputation for academic excellence.",
        "Modern campus with top-class facilities and a focus on student development.",
        "Collaboration with industry for enhanced career opportunities.",
        "Focus on research and innovation in the field of engineering.",
      ],
    },
    admissionProcess: {
      title:
        "Admission Process in Bharati Vidyapeeth College of Engineering in 2025",
      data: [
        "Candidates need to appear for the BVIMR (Bharati Vidyapeeth Institute of Management and Research) entrance exam.",
        "Shortlisted candidates will be invited for Group Discussion and Personal Interview rounds.",
        "Candidates must submit required documents like exam scorecard, academic certificates, and identity proof.",
        "The candidate will be provisionally admitted to the program upon payment of the necessary yearly tuition fees.",
      ],
    },
    whyChoose: {
      title:
        "Why Choose Bharati Vidyapeeth College of Engineering Admission in 2025",
      data: [
        "Well-known for its strong academic programs and faculty.",
        "Excellent infrastructure and modern teaching methods.",
        "Active industry collaborations ensuring good placement opportunities.",
        "Opportunities to get involved in research and development.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria for Admission in Bharati Vidyapeeth College of Engineering in 2025",
      keyPoints: [
        "Candidates must have passed 10+2 or equivalent with a minimum of 45% marks in Physics, Chemistry, and Mathematics (40% for reserved categories).",
        "A valid JEE Main score is required.",
        "Candidates must be at least 17 years old by the time of admission.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents for Admission in Bharati Vidyapeeth College of Engineering in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "JEE Main scorecard.",
        "Proof of identity and recent passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate and income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Bharati Vidyapeeth Pune",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/bharati-vidyapeeth-1.png",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/bharati-vidyapeeth-2.jpg",
    ],
    collegeData: [
      "Bharati Vidyapeeth Deemed to be University, established in 1964 by Dr. Patangrao Kadam in Pune, is one of India's leading institutions dedicated to providing an exceptional academic experience. With a mission to foster intellectual growth and offer transformative educational opportunities, the university has expanded to 8 campuses across India.",
      "Renowned for its commitment to academic excellence, Bharati Vidyapeeth offers a wide range of educational programs that help students excel in both academics and research. The university organizes various seminars, workshops, and webinars to deepen students' understanding of their subjects. Students benefit from academic freedom, as they can choose from a diverse array of programs in fields such as Medicine, Dentistry, Ayurveda, Homeopathy, Nursing, Pharmacy, Engineering, Management, Hotel Management, Environmental Science, Agriculture, and more.",
      "Bharati Vidyapeeth enhances the learning experience with state-of-the-art infrastructure, including spacious classrooms, well-equipped research labs, and modern amenities across all its campuses. The faculty members are dedicated to providing high-quality education, using internationally recognized teaching methods to ensure that students not only excel academically but also develop holistically, focusing on personality growth alongside their studies.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in Bharati Vidyapeeth Pune in 2025",
      keyPoints: [
        "Reputed institution with a strong academic track record.",
        "Modern infrastructure with cutting-edge laboratories and smart classrooms.",
        "Excellent industry linkages for internships and placements.",
        "A focus on innovation and research with opportunities for students to engage in hands-on projects.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Bharati Vidyapeeth Pune in 2025",
      data: [
        "Candidates need to appear for the BVIMR (Bharati Vidyapeeth Institute of Management and Research) entrance exam.",
        "Shortlisted candidates will be invited for Group Discussion and Personal Interview rounds.",
        "Candidates must submit required documents like exam scorecard, academic certificates, and identity proof.",
        "The candidate will be provisionally admitted to the program upon payment of the necessary yearly tuition fees.",
      ],
    },
    whyChoose: {
      title: "Why Choose Bharati Vidyapeeth Pune Admission in 2025",
      data: [
        "Strong reputation for academic excellence and research.",
        "Well-equipped infrastructure and industry-focused curriculum.",
        "Excellent placement opportunities with leading companies.",
        "Active focus on innovation, creativity, and student development.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria for Admission in Bharati Vidyapeeth Pune in 2025",
      keyPoints: [
        "Candidates must have passed 10+2 or equivalent with at least 45% marks in Physics, Chemistry, and Mathematics (40% for reserved categories).",
        "A valid JEE Main score is required.",
        "Candidates must be at least 17 years old by the time of admission.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents for Admission in Bharati Vidyapeeth Pune in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "JEE Main scorecard.",
        "Proof of identity and recent passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate and income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Vit Vellore",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/vit-1.png",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/vit-2.webp",
    ],
    collegeData: [
      "VIT was founded with the goal of providing high-quality higher education that meets international standards. The university continually strives to adopt innovative methods to enhance the quality of education. The campus boasts a cosmopolitan atmosphere with students from all over the world, and experienced faculty members are committed to nurturing and guiding them. The global standards set by VIT in teaching and research drive its relentless pursuit of excellence, which has become an integral part of its culture. The university takes immense pride in its highly motivated students, who contribute to its growing reputation.",
      "VIT was established under Section 3 of the University Grants Commission (UGC) Act, 1956, and initially founded in 1984 as Vellore Engineering College, a self-financing institution. In 2001, the Union Ministry of Human Resources Development granted university status to Vellore Engineering College, transforming it into a prestigious institution. The university is led by its founder and Chancellor, Dr. G. Viswanathan, a former Parliamentarian and Minister in the Tamil Nadu Government, who has been honored with an honorary doctorate by West Virginia University for his contributions to world-class education.",
      "VIT’s strong international collaborations, through Memoranda of Understanding with various global universities, enable student and faculty exchanges and foster joint research projects. These partnerships contribute to the university's reputation for excellence in research, as demonstrated by its students who undertake research projects at foreign universities, bringing respect and recognition to India. With steady progress and a clear vision, VIT continues to advance in its mission to provide world-class education and research opportunities.",
    ],
    collegeAdvantage: {
      title: "Advantages Of Taking Admission in VIT Vellore in 2025",
      keyPoints: [
        "VIT offers world-class education with a focus on innovative teaching methods and research.",
        "The university has a cosmopolitan campus environment with students from all over the world, promoting cultural diversity.",
        "Experienced faculty members are dedicated to nurturing students and ensuring their academic and personal growth.",
        "VIT has strong international collaborations, providing opportunities for student and faculty exchanges and joint research projects.",
        "The university’s reputation for excellence is supported by its robust infrastructure and state-of-the-art facilities.",
        "VIT’s strong focus on research and industry partnerships enhances the employability and global recognition of its graduates.",
        "The university offers a wide range of programs across various disciplines, ensuring academic freedom and flexibility for students.",
        "VIT’s continuous pursuit of excellence has made it one of the top educational institutions in India and globally.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in VIT Vellore in 2025",
      data: [
        "VIT Vellore admissions for 2025 are primarily based on entrance exams for most courses.",
        "The university conducts VITEEE for BTech, VITMEE for MTech, and VITREE for PhD admissions.",
        "Additionally, VIT Vellore accepts national-level entrance exams such as NATA, GATE, and others for specific courses.",
        "Candidates must appear for one of these entrance exams and qualify for the Personal Interview round to be considered for final selection.",
      ],
    },
    whyChoose: {
      title: "Why Choose VIT Vellore Admission in 2025",
      data: [
        "VIT Vellore offers over 14 courses at undergraduate, postgraduate, and doctoral levels across various disciplines, including Engineering, Management, and Science.",
        "It has Ranked at 11th position by National Institutional Ranking Framework (NIRF) 2024",
        "Top-tier institution with excellent academic programs.",
        "World-class facilities and infrastructure.",
        "Industry-driven curriculum that ensures a strong career path.",
        "Vibrant campus life with ample extracurricular activities.",
      ],
    },
    eligibility: {
      title: "Eligibility Criteria for Admission in VIT Vellore in 2025",
      keyPoints: [
        "Candidates must have passed 10+2 or equivalent with a minimum of 65% marks in Physics, Chemistry, and Mathematics.",
        "A valid score in JEE Main or VITEEE is required.",
        "Candidates must be at least 17 years old by the time of admission.",
      ],
    },
    requireDocuments: {
      title: "Required Documents for Admission in VIT Vellore in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "JEE Main or VITEEE scorecard.",
        "Proof of identity and recent passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate and income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Manipal University",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/manipal-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/manipal-2.jpg",
    ],
    collegeData: [
      "Manipal University named after MAHE, Established in 1953. The Manipal Academy of Higher Education (MAHE) is a private university located in Karnataka, India. It has been awarded the Institution of Excellence status by the Ministry of Education, Government of India. The university is accredited A++ by NAAC and offers over 300 programs. In addition to its main campus, MAHE has off-campuses in Mangaluru, Bengaluru, and Jamshedpur, and an offshore campus in Dubai (UAE). Ranked 4th in the 'Universities' category and 56th in the 'Engineering' category by NIRF 2024, it has also been ranked in the 901-950 range by QS World University Rankings 2025.",
      "MAHE offers a wide range of undergraduate, postgraduate, PhD, and other programs across multiple disciplines, including Medicine, Engineering, Technology, Humanities, Management, Arts, Science, and Commerce. Admission to some courses is merit-based, while others consider entrance exam scores. The university is ISO certified and approved by the University Grants Commission (UGC). Its academic excellence and global recognition make it one of the top universities in India and abroad.",
      "Some of the most sought-after courses at MAHE include BTech, BSc, BBA, BPT, BDS, BA, BDes, BArch, MTech, MBA, MCA, and MDes. The university offers these programs at reasonable fees, ensuring students can acquire valuable knowledge and skills in their chosen field of interest. Additionally, MAHE provides an excellent infrastructure that enhances the overall student experience and supports their academic and personal growth.",
    ],
    collegeAdvantage: {
      title: "Advantages Of Taking Admission in Manipal University in 2025",
      keyPoints: [
        "A prestigious institution with a global reputation.",
        "Modern infrastructure and top-quality educational resources.",
        "Comprehensive industry interactions and placement opportunities.",
        "A student-centered environment that fosters innovation and creativity.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Manipal University in 2025",
      data: [
        "MAHE offers admission to 594 undergraduate and postgraduate courses.",
        "Admission to some courses is merit-based, while others consider entrance exam scores.",
        "MAHE Manipal admission is based on MET (Manipal Entrance Exam) and other national-level exams like CAT, XAT, GMAT, depending on the course.",
        "Admissions can be done through both online and offline modes.",
        "The admission process involves submitting the application form, verifying documents, and paying the required fees.",
        "After qualifying the entrance exams, students must attend the counselling process.",
      ],
    },
    whyChoose: {
      title: "Why Choose Manipal University Admission in 2025",
      data: [
        "Manipal University Academy of Higher Education (MAHE) is a prestigious university with over 300 programs across various disciplines, providing a wide range of academic choices.",
        "It has a global reputation, ranked 4th among Indian universities and 56th in Engineering by NIRF 2024, and in the 901-950 range by QS World University Rankings 2025.",
        "MAHE offers excellent infrastructure, modern facilities, and state-of-the-art laboratories, creating an ideal environment for academic and personal growth.",
        "The university has strong international collaborations with reputed universities, enabling students to gain global exposure and participate in exchange programs.",
        "Manipal University provides a wide range of undergraduate, postgraduate, and doctoral programs in Medicine, Engineering, Humanities, Management, Science, Arts, and more.",
        "It has a diverse student body with students from all over India and abroad, fostering a multicultural learning environment.",
        "MAHE is ISO certified and UGC approved, ensuring high academic standards and quality education.",
        "The university offers competitive and reasonable fee structures, ensuring accessibility to quality education for students from different financial backgrounds.",
        "The campus provides an active placement cell, industry connections, and internships to help students with career development and job placements.",
      ],
    },
    eligibility: {
      title: "Eligibility Criteria for Admission in Manipal University in 2025",
      keyPoints: [
        "Candidates must have passed 10+2 or equivalent with a minimum of 45% marks in Physics, Chemistry, and Mathematics.",
        "A valid score in JEE Main, MET, KCET is required.",
      ],
    },
    requireDocuments: {
      title: "Required Documents for Admission in Manipal University in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "JEE Main, KCET or MET scorecard.",
        "Proof of identity and recent passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate and income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Mit Pune",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/mit-1.png",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/mit-2.webp",
    ],
    collegeData: [
      "MIT-Pune (MIT College of Engineering) is one of the oldest and most renowned engineering colleges in Pune, known for its excellence in education and infrastructure.",
      "MIT-WPU offers a transformative learning experience that equips students with the skills and knowledge needed to thrive in today’s fast-evolving business world. The curriculum, developed in collaboration with industry experts, ensures students gain practical insights aligned with current business demands.",
      "At MIT-WPU, students learn from distinguished faculty who combine theoretical expertise with real-world experience, supported by state-of-the-art facilities and a conducive learning environment. The university focuses on holistic development, fostering leadership, communication, and interpersonal skills alongside academic growth.",
      "The direct admission process is simple, with eligibility based on a Bachelor's degree from a recognized university, and selection based on academic credentials and an assessment of aptitude and communication skills.",
    ],
    collegeAdvantage: {
      title: "Advantages of Taking Admission in MIT-Pune in 2025",
      keyPoints: [
        "Focus on global peace and sustainability in business.",
        "Well-rounded curriculum that includes practical training, case studies, and industry exposure.",
        "State-of-the-art campus with modern infrastructure and research facilities.",
        "Collaborations with global universities for exchange programs.",
        "Holistic development with courses in ethics, leadership, and social responsibility.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in MIT-Pune in 2025",
      data: [
        "MIT-Pune selection process for B.Tech Programmes is based on one of the following entrance exams: JEE 2025, MHT-CET 2025, PERA 2025, MHT-CET-B 2025, or NEET 2025 (only for Bioengineering and Food Engineering programs). These exams are conducted by the Competent Authority.",
        "Stuntent also have to submit Statement of Purpose (SOP) For a Minimum 500 Words",
        "Merit-listed candidates will receive an email with the offer letter and fee payment instructions within 5 days.",
      ],
    },
    whyChoose: {
      title: "Why Choose MIT-Pune Admission in 2025",
      data: [
        "MIT-WPU is renowned for its top-tier education and innovative learning approach",
        "MIT focuses on developing leadership and communication skills through extracurricular activities",
        "he university offers modern classrooms, well-equipped libraries, and advanced technology for an optimal learning experience",
        "Global exposure through exchange programs.",
        "Comprehensive curriculum with specializations in cutting-edge business fields.",
        "A campus that nurtures innovation, creativity, and mindfulness.",
      ],
    },
    eligibility: {
      title: "Eligibility Criteria Admission in MIT-Pune in 2025",
      keyPoints: [
        "Candidates should have secured at least 50% aggregate marks in their 10+2/Class 12th or equivalent exam, with subjects including Physics, Chemistry, and Mathematics (PCM) or Physics, Chemistry, and Biology (PCB).",
        "For Bioengineering and Food Engineering programs, candidates from Maharashtra belonging to reserved categories must have a minimum of 45% marks.",
        "Alternatively, candidates who have completed a Diploma in Engineering & Technology with at least 60% marks in an appropriate branch from a Government-approved technical board are eligible.",
        "For candidates from the Backward class category residing in Maharashtra, a minimum aggregate of 45% marks is acceptable.",
        "Candidates must possess a valid score in National Level Tests like JEE, MHT-CET, PERA, MHT-CET-B, or NEET",
      ],
    },
    requireDocuments: {
      title: "Required Documents for Admission in MIT-Pune in 2025",
      keyPoints: [
        "Entrance exam scorecard (CAT, MAT, Xat, etc).",
        "A copy of the Bachelor's degree certificate from a recognized university or institution",
        "Graduation mark sheets and degree certificate.",
        "Statement of Purpose (SOP)",
        "Identity proof (Aadhar card, passport, etc.).",
        "Recent passport-sized photographs.",
        "Category certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "R V College Of Engineering",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/rv-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/rv-2.jpg",
    ],
    collegeData: [
      "RV College of Engineering (RVCE), established in 1963, is a self-financing engineering college managed by the Rashtreeya Sikshana Samithi Trust. Located 13 km from the center of Bangalore, known as the Silicon Valley of India, the college is affiliated with Visvesvaraya Technological University and accredited by the NBA. RVCE is approved by AICTE and the Government of Karnataka, and it is ranked 99th in the Engineering category of NIRF Rankings 2024.",
      "RVCE offers over 35 undergraduate, postgraduate, and doctoral courses across 15 departments in fields like Engineering & Technology and Science. Popular courses include BE/BTech, MTech, and MCA. Admissions are based on merit in exams such as COMEDK UGET, JEE Mains, and others. The college’s placement records are impressive, with the highest salary package in 2024 being INR 92 LPA, and the average package reaching INR 35 LPA.",
      "The college campus spans 16.85 acres on Mysore Road, Bangalore, and is equipped with essential amenities such as cafeterias, a gym, a dispensary, transportation, smart classrooms, an auditorium, a conference hall, and separate hostels for boys and girls. RVCE also offers merit-cum-means scholarships funded by government agencies, corporate bodies, NGOs, and alumni, providing financial support to deserving students.",
    ],
    collegeAdvantage: {
      title:
        "Advantages Of Taking Admission in R.V. College of Engineering in 2025",
      keyPoints: [
        "RVCE is ranked 99th in the Engineering category of NIRF Rankings 2024, showcasing its academic excellence.",
        "The college offers over 35 courses at UG, PG, and doctoral levels in Engineering, Technology, and Science.",
        "RVCE has a strong placement record with the highest package in 2024 being INR 92 LPA and an average package of INR 35 LPA.",
        "The campus is located in Bangalore, known as the Silicon Valley of India, providing excellent industry connections.",
        "RVCE is approved by AICTE and the Government of Karnataka, ensuring high educational standards.",
        "The college provides a wide range of scholarships, including government, corporate, NGO, and alumni-funded scholarships for financial assistance.",
        "The campus is spread across 16.85 acres and equipped with modern facilities like smart classrooms, hostels, gyms, cafeterias, and transportation.",
        "RVCE is affiliated with Visvesvaraya Technological University and accredited by the NBA, adding credibility to its programs.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in R.V. College of Engineering in 2025",
      data: [
        "Candidates need to have valid COMEDK, UGET, KCET or JEE Main scores and complete their application online.",
        "The selection is based on merit, with counseling for shortlisted candidates.",
        "RVCE provides all necessary support to students throughout the admission process.",
      ],
    },
    whyChoose: {
      title: "Why Choose R.V. College of Engineering Admission in 2025",
      data: [
        "One of the leading engineering colleges in India with a reputation for excellence.",
        "World-class facilities, research centers, and infrastructure.",
        "Great industry collaboration ensuring excellent career opportunities.",
        "Vibrant campus life and a strong alumni network.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria for Admission in R.V. College of Engineering in 2025",
      keyPoints: [
        "Candidates must have passed 10+2 or equivalent with a minimum of 45% marks in Physics, Chemistry, and Mathematics (40% for reserved categories).",
        "A valid COMEDK, UGET, KCET or JEE Main score is required for admission.",
        "Candidates must be at least 17 years old by the time of admission.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents for Admission in R.V. College of Engineering in 2025",
      keyPoints: [
        "10th and 12th grade mark sheets or equivalent.",
        "COMEDK, UGET, KCET or JEE Main scorecard.",
        "Proof of identity and recent passport-sized photographs.",
        "Transfer and migration certificates (if applicable).",
        "Category certificate and income certificate (if applicable).",
      ],
    },
  },

  // MBA Course

  {
    collegeName: "Symbiosis International Deemed University",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/symbiosis-deemed-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/symbiosis-deemed-2.jpg",
    ],
    collegeData: [
      "Symbiosis International Deemed University is known for its world-class MBA program that blends academic rigor with practical business skills.",
      "The MBA program offers specializations in Marketing, Finance, HR, Operations, and more, providing students with ample opportunities to build expertise in their chosen field.",
      "The institution fosters an entrepreneurial mindset and leadership skills, preparing graduates for top positions in global companies.",
    ],
    collegeAdvantage: {
      title:
        "Advantages of Taking Admission in Symbiosis International Deemed University MBA in 2025",
      keyPoints: [
        "Highly reputed institution with a global recognition in business education.",
        "Excellent industry connect providing internship and placement opportunities with top firms.",
        "Experienced faculty with academic and professional expertise.",
        "State-of-the-art infrastructure, including modern classrooms, libraries, and case study facilities.",
        "Focus on holistic development through leadership, communication, and analytical skills.",
        "Extensive alumni network offering mentorship and career opportunities.",
      ],
    },
    admissionProcess: {
      title:
        "Admission Process in Symbiosis International Deemed University MBA in 2025",
      data: [
        "Applicants must appear for the SNAP (Symbiosis National Aptitude Test) exam.",
        "Shortlisted candidates are called for further selection rounds, including Group Exercise, Personal Interview, and Written Ability Test (WAT).",
        "Documents such as SNAP scorecard, graduation marksheets, and identity proof must be submitted for final admission.",
      ],
    },
    whyChoose: {
      title:
        "Why Choose Symbiosis International Deemed University MBA Admission in 2025",
      data: [
        "One of India's premier MBA programs with a global reputation.",
        "Strategic location in Pune, known as the education hub of India.",
        "Robust curriculum with an emphasis on innovation, leadership, and business strategy.",
        "Global exposure through exchange programs and partnerships with international universities.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Symbiosis International Deemed University MBA in 2025",
      keyPoints: [
        "A graduate degree from a recognized university with a minimum of 50% marks.",
        "Valid SNAP score is mandatory for all applicants.",
        "Candidates must have completed their graduation by the time of admission.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents for Admission in Symbiosis International Deemed University MBA in 2025",
      keyPoints: [
        "SNAP scorecard and admit card.",
        "Mark sheets of 10th, 12th, and graduation.",
        "Valid photo ID proof (Aadhar, Passport, etc.).",
        "Passport-sized photographs for administrative purposes.",
        "Category certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Bharati Vidyapeeth",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/bharati-vidyapeeth-1.png",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/bharati-vidyapeeth-2.jpg",
    ],
    collegeData: [
      "Bharati Vidyapeeth is a prestigious institution offering a high-quality MBA program that emphasizes both academic excellence and practical exposure. The college provides a well-maintained infrastructure, including state-of-the-art classrooms and a comprehensive library, fostering an ideal learning environment. With a diverse student body, students have the opportunity to learn about different cultures, enhancing their global perspective.",
      "The MBA program offers a wide array of specializations, enabling students to tailor their education to their individual career goals. Real-world learning is integrated into the curriculum through project work, assignments, and company visits. The college is affiliated with NAAC, ensuring top-tier academic standards and has strong recruitment ties with reputable companies.",
      "Bharati Vidyapeeth also offers flexibility in its admission process, including the option to appear for the BMAT exam even in the final year of graduation. Students are supported by expert faculty and advisors, who guide them throughout their educational journey. Campus placements and direct admission options through management quota further enhance the prospects for graduates. The college’s emphasis on sustainable practices, online resource access, and the availability of essential admission documents makes it a well-rounded choice for aspiring MBA students.",
    ],
    collegeAdvantage: {
      title: "Advantages of Taking Admission in Bharati Vidyapeeth MBA in 2025",
      keyPoints: [
        "Renowned faculty with industry experience.",
        "Strong industry tie-ups and placement assistance.",
        "Advanced learning resources including computer labs, libraries, and research centers.",
        "A vibrant campus with various student clubs and extracurricular activities.",
        "Emphasis on entrepreneurship and innovation.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Bharati Vidyapeeth MBA in 2025",
      data: [
        "Candidates need to appear for the BVIMR (Bharati Vidyapeeth Institute of Management and Research) entrance exam.",
        "Shortlisted candidates will be invited for Group Discussion and Personal Interview rounds.",
        "Candidates must submit required documents like exam scorecard, academic certificates, and identity proof.",
        "The candidate will be provisionally admitted to the program upon payment of the necessary yearly tuition fees.",
      ],
    },
    whyChoose: {
      title: "Why Choose Bharati Vidyapeeth MBA Admission in 2025",
      data: [
        "Well-maintained infrastructure with a positive learning environment and a well-stocked library",
        "Comprehensive MBA program with diverse specializations.",
        "Experienced faculty with expertise in different business sectors.",
        "Access to global opportunities through international collaborations.",
        "A strong alumni network and industry connections.",
      ],
    },
    eligibility: {
      title: "Eligibility Criteria Admission in Bharati Vidyapeeth MBA in 2025",
      keyPoints: [
        "Bachelor's degree in any discipline from a recognized university with a minimum of 50% marks.",
        "Admission is granted based on merit and the marks obtained in the SNAP Computer Base Test (CBT) Score, which serves as the primary selection criterion",
        "Candidates who are appearing for their final year examinations can also apply for admission, provided they secure at least 50% marks.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents for Admission in Bharati Vidyapeeth MBA in 2025",
      keyPoints: [
        "BMAT exam scorecard",
        "Graduation mark sheets and degree certificate.",
        "Aadhar card or any valid photo ID.",
        "Passport-sized photographs.",
        "Migration and Transfer certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Mit World Peace University",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/mit-1.png",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/mit-2.webp",
    ],
    collegeData: [
      "MIT World Peace University offers an MBA program that is focused on leadership, innovation, and business ethics.  MIT-WPU offers a transformative learning experience that equips students with the skills and knowledge needed to thrive in today’s fast-evolving business world. The curriculum, developed in collaboration with industry experts, ensures students gain practical insights aligned with current business demands.",
      "At MIT-WPU, students learn from distinguished faculty who combine theoretical expertise with real-world experience, supported by state-of-the-art facilities and a conducive learning environment. The university focuses on holistic development, fostering leadership, communication, and interpersonal skills alongside academic growth.",
      "The direct admission process is simple, with eligibility based on a Bachelor's degree from a recognized university, and selection based on academic credentials and an assessment of aptitude and communication skills.",
    ],
    collegeAdvantage: {
      title:
        "Advantages of Taking Admission in MIT World Peace University MBA in 2025",
      keyPoints: [
        "Focus on global peace and sustainability in business.",
        "Well-rounded curriculum that includes practical training, case studies, and industry exposure.",
        "State-of-the-art campus with modern infrastructure and research facilities.",
        "Collaborations with global universities for exchange programs.",
        "Holistic development with courses in ethics, leadership, and social responsibility.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in MIT World Peace University MBA in 2025",
      data: [
        "Applicants must submit a valid score from CAT, MAT, or the MIT entrance exam.",
        "Shortlisted candidates will be called for a Personal Interview and Group Discussion.",
        "Candidates must provide their academic documents, entrance exam scorecards, and personal identification proof.",
      ],
    },
    whyChoose: {
      title: "Why Choose MIT World Peace University MBA Admission in 2025",
      data: [
        "MIT-WPU is renowned for its top-tier education and innovative learning approach",
        "MIT focuses on developing leadership and communication skills through extracurricular activities",
        "The MBA program is designed with input from industry experts, ensuring practical business skills.",
        "he university offers modern classrooms, well-equipped libraries, and advanced technology for an optimal learning experience",
        "Global exposure through exchange programs.",
        "Comprehensive curriculum with specializations in cutting-edge business fields.",
        "A campus that nurtures innovation, creativity, and mindfulness.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in MIT World Peace University MBA in 2025",
      keyPoints: [
        "Candidates must have secured a minimum of 60% aggregate marks in their Bachelor's degree from a UGC recognized university or an equivalent institution",
        "For candidates from the Backward class category residing in Maharashtra, a minimum aggregate of 55% marks is acceptable.",
        "Candidates must possess a valid score in National Level Tests like CAT, MAT, Xat, etc",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents for Admission in MIT World Peace University MBA in 2025",
      keyPoints: [
        "Entrance exam scorecard (CAT, MAT, Xat, etc).",
        "A copy of the Bachelor's degree certificate from a recognized university or institution",
        "Graduation mark sheets and degree certificate.",
        "Identity proof (Aadhar card, passport, etc.).",
        "Recent passport-sized photographs.",
        "Category certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Narsee Monjee Institute Of Management",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/nmims-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/nmims-2.jpg",
    ],
    collegeData: [
      "Narsee Monjee Institute Of Management (NMIMS) is one of the most prestigious business schools in India, offering an MBA program that provides a deep understanding of management principles and business practices.",
      "The MBA program at NMIMS includes specializations in Marketing, Finance, Operations, HR, and Business Analytics, among others, to cater to diverse career aspirations.",
      "The college focuses on academic excellence and leadership development, ensuring that graduates are well-prepared for managerial roles in top organizations worldwide.",
    ],
    collegeAdvantage: {
      title: "Advantages of Taking Admission in NMIMS MBA in 2025",
      keyPoints: [
        "Recognized as one of India's top business schools, consistently ranked among the best.",
        "A highly flexible curriculum with various specializations and elective options.",
        "World-class faculty with expertise in global business practices.",
        "Strategic location in Mumbai, providing students with access to top corporates and industries.",
        "Strong alumni network offering mentorship and career opportunities.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in NMIMS MBA in 2025",
      data: [
        "Register and apply for the NMAT exam (Apply to NMIMS before your first attempt at the NMAT exam)",
        "Shortlisted Candidate Need to Participate in CD-PI (Case Discussion and Personal Interview)",
        "Based on CD-PI & NMAT Score, & eligibity, the candidate will be selected for admission.",
      ],
    },
    whyChoose: {
      title: "Why Choose NMIMS MBA Admission in 2025",
      data: [
        "NMIMS offers a cutting-edge curriculum designed in collaboration with industry leaders.",
        "Strong emphasis on leadership, entrepreneurship, and international exposure.",
        "Innovative teaching methods, including case studies, industry internships, and live projects.",
        "NMIMS have proven track record of successful admissions commitment to excellence",
        "Well-established placement record with top multinational companies.",
      ],
    },
    eligibility: {
      title: "Eligibility Criteria Admission in NMIMS MBA in 2025",
      keyPoints: [
        "Completion of 10+2 or equivalent examination from a recognized board or university",
        "Participation in the National Eligibility cum Entrance Test (NEET) and obtaining a qualifying score in accordance with Medical Council of India (MCI) and University Grants Commission (UGC) regulations",
        "A minimum score of 500 marks in the NEET entrance exam",
        "Study of Physics, Chemistry, Biology, and English as compulsory subjects in the qualifying examination",
        "Candidates should have completed their graduation by the time of admission.",
        "A minimum age of 17 years at the time of admission to the MBBS program",
      ],
    },
    requireDocuments: {
      title: "Required Documents for Admission in NMIMS MBA in 2025",
      keyPoints: [
        "NEET scorecard or other relevant entrance exam scorecard.",
        "10th & 12th marksheet , passing certificate",
        "Graduation mark sheets and degree certificate (if applicable).",
        "Proof of identity (Aadhar card, Passport, etc.).",
        "Passport-sized photographs.",
        "Category or Income certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Welingkar Institute Of Management Development and Research",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/welingkarr-1.jpeg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/welingkarr-2.jpg",
    ],
    collegeData: [
      "Wellingkar stands as a top choice, offering a blend of academic excellence, innovative teaching methods, and unparalleled career opportunities. Renowned for its cutting-edge curriculum and industry-relevant courses, Wellingkar Mumbai is recognized as one of the top business schools in the country.",
      "With a strong focus on holistic development, Wellingkar provides students with the skills needed to excel in a fast-evolving global business landscape. The institute boasts a dynamic faculty, a world-class infrastructure, and a student-centric approach to learning.",
      "In Wellingkar, Students are encouraged to engage in real-world projects and case studies, making the learning experience highly practical and hands-on.",
    ],
    collegeAdvantage: {
      title:
        "Advantages of Taking Admission in Welingkar Institute MBA in 2025",
      keyPoints: [
        "Innovative programs that emphasize creative thinking and entrepreneurship.",
        "Strong industry connections and tie-ups with top companies for internships and placements.",
        "Experienced faculty and visiting professors from top industries.",
        "A vibrant campus with a focus on leadership development and extracurricular activities.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in Welingkar Institute MBA in 2025",
      data: [
        "Interested applicants need to complete an online application form on the official Welingkar College website, providing personal details, academic qualifications, entrance exam scores, work experience (if any), and other relevant information",
        "Candidates are shortlisted based on their entrance exam scores for participation in group discussions (GD) and personal interviews (PI)",
        "The final list of selected candidates is announced, considering academic qualifications, entrance exam scores, performance in GD and PI, and other criteria established by the college",
      ],
    },
    whyChoose: {
      title: "Why Choose Welingkar Institute MBA Admission in 2025",
      data: [
        "A strong emphasis on creativity and innovation in business education.",
        "Collaborative learning environment with a focus on team-based projects and industry internships.",
        "Accredited by leading bodies, ensuring a globally recognized degree.",
        "Providing access to a vibrant business ecosystem.",
      ],
    },
    eligibility: {
      title:
        "Eligibility Criteria Admission in Welingkar Institute MBA in 2025",
      keyPoints: [
        "Candidates must have a bachelor's degree from a recognized institution with a minimum aggregate score, typically ranging between 50% and 60%, is required",
        "Valid score in entrance exams like CAT, XAT, CMAT, etc.",
        "Even though direct admission bypasses traditional entrance exams like CAT, XAT, CMAT, GMAT, or ATMA, there may still be minimum score requirements.",
        "For specific programs, candidates may need to participate in the National Eligibility cum Entrance Test (NEET) and achieve a qualifying score, as per the regulations set by the Medical Council of India (MCI) and University Grants Commission (UGC).",
        "Candidates must meet any additional requirements outlined by the institution, including submitting relevant documents, medical fitness certificates, and complying with the institution's admission policies.",
      ],
    },
    requireDocuments: {
      title:
        "Required Documents for Admission in Welingkar Institute MBA in 2025",
      keyPoints: [
        "Provide a copy of your degree certificate or provisional certificate",
        "Provide proof of your entrance exam scores (if applicable)",
        "Include a copy of your work experience certificate (if applicable).",
        "Identity proof (Aadhar, Passport, etc.).",
        "Recent passport-sized photographs.",
        "Category certificate (if applicable).",
      ],
    },
  },
  {
    collegeName: "Jamnalal Bajaj Institute Of Management Studies",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/jbims-1.jpg",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/jbims-2.webp",
    ],
    collegeData: [
      "Jamnalal Bajaj Institute of Management Studies (JBIMS), is one of India’s premier business schools, known for its rich legacy, academic excellence, and strategic location in the heart of Mumbai, India’s financial capital. Established in 1965, JBIMS has earned a reputation for nurturing future leaders in business, offering a comprehensive range of management programs designed to equip students with cutting-edge knowledge, leadership skills, and a global perspective.",
      "The institute is recognized for its world-class faculty, strong industry connections, and innovative teaching methodologies. JBIMS offers a variety of MBA programs, including its flagship Master of Management Studies (MMS) program, as well as specialized Executive MBA courses aimed at experienced professionals. Its rigorous curriculum, combined with hands-on learning through case studies, internships, and live projects, prepares students to excel in the competitive business environment.",
      "JBIMS vast alumni network, comprising influential business leaders, entrepreneurs, and executives, further enhances the opportunities available to its students. JBIMS's focus on holistic development, entrepreneurial thinking, and ethical leadership ensures that its graduates are well-equipped to make a meaningful impact in the business world",
    ],
    collegeAdvantage: {
      title: "Advantages of Taking Admission in JBIMS MBA in 2025",
      keyPoints: [
        "Recognized as one of the top management institutes in India with strong alumni and industry connections.",
        "Rigorous academic curriculum that develops both academic and managerial skills.",
        "Outstanding placement record with top global and Indian firms recruiting from the campus.",
        "Faculty with strong academic backgrounds and practical industry experience.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in JBIMS MBA in 2025",
      data: [
        "The application form will require details of academic background, test scores, work experience (if any), and personal information.",
        "Shortlisted candidates will be invited for a Group Discussions (GD) and Personal Interview (PI).",
        "Final selection is based on academic performance, test scores, work experience, extracurricular achievements, and performance in the selection rounds.",
      ],
    },
    whyChoose: {
      title: "Why Choose JBIMS MBA Admission in 2025",
      data: [
        "A prestigious institute with a legacy of producing industry leaders.",
        "World-class curriculum designed to equip students with the skills to succeed in global business.",
        "Strong industry connections with top recruiters offering excellent placement opportunities.",
        "A collaborative learning environment that fosters leadership, teamwork, and innovation.",
      ],
    },
    eligibility: {
      title: "Eligibility Criteria Admission in JBIMS MBA in 2025",
      keyPoints: [
        "Candidates must possess a bachelor's degree from a recognized university with a minimum aggregate score.",
        "A valid score in management entrance exams such as CAT, XAT, or CMAT is required.",
        "The cutoff percentile or score may vary based on the program and admission cycle",
        "Some programs may require submission of letters of recommendation from academic or professional references to validate qualifications, character, and potentia",
        "Submit a Statement of Purpose (SOP) detailing career goals, motivations for pursuing an MBA, and alignment with JBIMS’s values",
      ],
    },
    requireDocuments: {
      title: "Required Documents for Admission in JBIMS MBA in 2025",
      keyPoints: [
        "CAT or CMAT Entrance exam scorecard.",
        "10th & 12th marksheet , passing certificate",
        "Graduation mark sheets and degree certificate (if applicable).",
        "Identity proof (Aadhar card, Passport, etc.).",
        "Recent Passport-sized photographs.",
        "Category certificate (if applicable).",
        "Statement of Purpose (SOP) outlining career goals, motivations for pursuing an MBA, and why JBIMS is the right fit for the candidate's aspirations",
      ],
    },
  },
  {
    collegeName:
      "Sydenham Institute Of Management Studies Research and Entrepreneurship Education",
    sliderImg: [
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/simsree-1.png",
      "/assets/img/webp/MedicalCounsellingIndia/direct-admission-college/simsree-2.jpg",
    ],
    collegeData: [
      "Sydenham Institute of Management Studies, Research and Entrepreneurship Education (SIMSREE), is one of India's most prestigious and well-established management institutes, known for its academic excellence and legacy of producing industry-ready professionals. Situated in the heart of Mumbai, SIMSREE offers a dynamic and vibrant environment for students to cultivate leadership, critical thinking, and managerial skills.",
      "It was Founded in 1983, SIMSREE is affiliated with the University of Mumbai and offers a comprehensive two-year MBA program that is recognized nationally and internationally. With a focus on both theoretical knowledge and practical application, SIMSREE’s curriculum is designed to equip students with the skills required to excel in the competitive global business environment. The institute offers a wide range of specializations, allowing students to tailor their MBA experience according to their career aspirations.",
      "SIMSREE is renowned for its distinguished faculty, who bring a wealth of industry experience and academic expertise to the classroom. The institute’s holistic approach to education emphasizes not only academic rigor but also personal and professional development. With state-of-the-art facilities, a robust alumni network, and strong ties with the industry, SIMSREE provides its students with ample opportunities to grow, learn, and excel.",
    ],
    collegeAdvantage: {
      title: "Advantages of Taking Admission in SIMSREE MBA in 2025",
      keyPoints: [
        "One of the oldest and most respected management institutes with a strong legacy of academic excellence.",
        "Offers a dynamic and rigorous MBA program with a focus on entrepreneurship and leadership.",
        "Strong placement record with leading firms across various sectors.",
        "Experienced faculty with industry experience and academic expertise.",
        "Offering easy access to top corporates, industries, and business networks.",
      ],
    },
    admissionProcess: {
      title: "Admission Process in SIMSREE MBA in 2025",
      data: [
        "Simsree offer Both Full Time and Part Time MBA Program.",
        "For full-time courses, applicants must submit an application form and valid scores from entrance exams such as CAT, MAH-CET, CMAT, or XAT",
        "For part-time courses, applicants are shortlisted based on their applications.",
        "Shortlisted part-time applicants undergo a telephonic interview to assess their suitability for the program.",
        "The final selection will be based on academic qualifications, entrance exam scores, and performance in telephonic interview.",
      ],
    },
    whyChoose: {
      title: "Why Choose SIMSREE MBA Admission in 2025",
      data: [
        "SIMSREE, established in 1983, is widely recognized for its excellence in management education.",
        "The faculty at SIMSREE, with their diverse academic and industry experience, enhances the learning process with valuable insights.",
        "SIMSREE maintains strong industry connections through guest lectures, workshops, and seminars, offering students practical exposure to real-world business challenges.",
        "The institute offers students ample opportunities to network with top professionals and industry leaders.",
        "A strong alumni network that provides mentorship and career opportunities to students.",
        "In addition to conventional management studies, SIMSREE places a strong emphasis on entrepreneurship, offering courses, workshops, and support for aspiring entrepreneurs.",
      ],
    },
    eligibility: {
      title: "Eligibility Criteria Admission in SIMSREE MBA in 2025",
      keyPoints: [
        "A bachelor’s degree in any discipline from a recognized university with a minimum of 50% marks (45% for reserved category).",
        "Candidates must obtain a valid score in a qualifying entrance exam such as CAT or CMAT, with the minimum percentile or score varying each year",
      ],
    },
    requireDocuments: {
      title: "Required Documents for Admission in SIMSREE MBA in 2025",
      keyPoints: [
        "Entrance exam scorecard (MAH CET, CAT, XAT, CMAT).",
        "Graduation mark sheets and degree certificate.",
        "Proof of identity (Aadhar card, Passport, etc.).",
        "Passport-sized photographs.",
        "Category certificate (if applicable).",
        "Transfer Certificate (TC) and Migration Certificate (if applicable).",
      ],
    },
  },
];
