import React, { useEffect, useState } from "react";
import "./DirectAdmissionTab.scss";
import { collegeData } from "./DirectAdmissionData";
import { Link } from "react-router-dom";

const DirectAdmissionTab = (props) => {
  const [tabData, seTabData] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);

  const handleCollegeData = (courseTitle, index) => {
    const filteredData = collegeData.find(
      (item) => item.course === courseTitle
    );

    if (filteredData !== undefined) {
      seTabData(filteredData);
    }

    setActiveIndex(index);
  };

  useEffect(() => {
    seTabData(collegeData[0]);
  }, []);

  const handleMenuItemClick = () => {
    if (props.handleClose) {
      props.handleClose();
    }
  };

  return (
    <div className="direct_admission_container">
      <div className="tab_section">
        {collegeData.map((curElem, index) => {
          return (
            <div
              key={index}
              id={`tab${index + 1}`}
              className={`tab ${activeIndex === index ? "active" : ""}`}
              onMouseOver={() => handleCollegeData(curElem.course, index)}
            >
              {activeIndex === index ? (
                <>
                  {curElem.course} <span style={{ fontSize: "28px" }}> »»</span>
                </>
              ) : (
                curElem.course
              )}
            </div>
          );
        })}
      </div>
      <div className="tab_content">
        {tabData?.colleges?.map((college, index) => (
          <Link
            key={index}
            to={`direct-admission/${college.link}`}
            onClick={handleMenuItemClick}
          >
            <div title={college.name} className="college_name">
              <span style={{ fontSize: "25px", fontWeight: 500 }}>🖹</span>
              {college.name}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default DirectAdmissionTab;
